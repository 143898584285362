import React from 'react'
import { useNavigate } from 'react-router-dom'

const TitleHeader = ({ className }) => {

    const navigate = useNavigate()

    return (
        <div className={`headerDividerBlock headerWrap flexbox ${className}`}>
            <div className="Navleft flexbox gap5 pointer" onClick={() => (navigate(-1))}>
                <img src="/assets/svg/ArrowLeft.svg" alt="" />
                <p className="text-14 text-black3">Master Profile</p>
            </div>
        </div>
    )
}

export default TitleHeader