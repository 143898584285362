import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '../../Components/Button/Button'
import MultiSelectDropdown from '../../Components/MultiSelectDropdown/MultiSelectDropdown'
import CalenderCustom from '../../Components/CalenderCustom/CalenderCustom'
import {
    engagementTypeOptions,
    programTypeOptions,
    focusAreaOptions,
    accHandlerOptions,
    engagementStatusOptions,
    tieringOptions,
    locationOptions
} from '../../Constants/FilterOptions'
import {
    clearState,
    getAllMasters,
    masterDataCsv,
    masterSliceData,
} from "../../reducer/masterSlice";
import { useNavigate } from "react-router-dom";
import SingleSelectDropdown from '../../Components/SingleSelectDropdown/SingleSelectDropdown'
import { use } from 'react'
import { useEffect } from 'react';
import LogoutPopup from '../../Components/Popups/LogoutPopup/LogoutPopup';
import { useRef } from 'react';
import DatanotFound from '../../Components/DatanotFound/DatanotFound';


const AllMastersList = ({
    showAddDetails,
    setShowAddDetails,
    setshowFilter,
    filter,
    searchTerm,
    message,
    setShowLogout,
    showLogout,
    setTotalMentors,
    totalMentors,
    filters,
    setFilters,
    initialFilterState
}) => {
    const options = [
        {
            label: "Pending",
            value: "pending",
        },
        {
            label: "Under Review",
            value: "review",
        },
        {
            label: "Requested a Revision",
            value: "requested",
        },
    ]

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { allMasters, isFetching, isFetchingCsv, isMastersFetching, isError, errorMessage, isSuccess, emasterId } = useSelector(masterSliceData);

    // ==== State & Clear Handlers ====
    // const initialFilterState = {
    //     engagementType: '',
    //     programType: '',
    //     focusArea: '',
    //     accHandler: '',
    //     engagementStatus: '',
    //     tiering: '',
    //     dateRange: null
    // };

    // const [filters, setFilters] = useState(initialFilterState);

    /////////Page///////////
    const [currPage, setCurrPage] = useState(1);

    const nextPage = () => {
        setCurrPage(currPage + 1)
        const obj = {
            focusAreas: filters.focusAreas,
            currentLocation: filters.currentLocation,
            engagement: filters.engagement,
            engagementType: filters.engagementType,
            programType: filters.programType,
            accHandler: filters.accHandler,
            engagementStatus: filters.engagementStatus,
            tiering: filters.tiering,
            dateRange: filters.dateRange,
            cohort: filters.cohort,
            courseType: filters.courseType,
            leadStage: filters.leadStage,
            leadSource: filters.leadSource,
            deliveryMode: filters.deliveryMode,
            industryType: filters.industryType,
            search: !searchTerm ? "" : searchTerm,
            pageNo: currPage + 1
        };
        dispatch(getAllMasters(obj))

    }

    const targetRef = useRef(null);

    useEffect(() => {

        const element = document.querySelector(".paginationAddon");

        const handleScroll = () => {
            if (targetRef?.current) {
                const { top } = targetRef?.current?.getBoundingClientRect();
                const windowHeight = window?.innerHeight || document?.documentElement?.clientHeight;
                if ((top - 20) <= windowHeight) {
                    console.log("top");
                    nextPage();
                }
            }
        };

        element?.addEventListener('scroll', handleScroll);

        return () => {
            element?.removeEventListener('scroll', handleScroll);
        };
    }, [nextPage]);

    /////////////////////////////////////////////////////////////////////////////    

    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const clearAllFilters = () => {
        setFilters(initialFilterState);
    };
    const applyAllFilters = () => {
        const obj = {
            focusAreas: filters.focusAreas,
            currentLocation: filters.currentLocation,
            engagement: filters.engagement,
            engagementType: filters.engagementType,
            programType: filters.programType,
            accHandler: filters.accHandler,
            engagementStatus: filters.engagementStatus,
            tiering: filters.tiering,
            dateRange: filters.dateRange,
            cohort: filters.cohort,
            courseType: filters.courseType,
            leadStage: filters.leadStage,
            leadSource: filters.leadSource,
            deliveryMode: filters.deliveryMode,
            industryType: filters.industryType,
            search: !searchTerm ? "" : searchTerm,
            pageNo: currPage,

        };
        console.log("calling filter obj", obj);

        dispatch(getAllMasters(obj));
    };
    const downloadData = () => {
        const obj = {
            focusAreas: filters.focusAreas,
            currentLocation: filters.currentLocation,
            engagement: filters.engagement,
            engagementType: filters.engagementType,
            programType: filters.programType,
            accHandler: filters.accHandler,
            engagementStatus: filters.engagementStatus,
            tiering: filters.tiering,
            dateRange: filters.dateRange,
            cohort: filters.cohort,
            courseType: filters.courseType,
            leadStage: filters.leadStage,
            leadSource: filters.leadSource,
            deliveryMode: filters.deliveryMode,
            industryType: filters.industryType,

        };
        console.log("masterDataCsv", obj);

        dispatch(masterDataCsv(obj));
    };


    useEffect(() => {
        if (message === '') {
            const obj = {
                focusAreas: filters.focusAreas,
                currentLocation: filters.currentLocation,
                engagement: filters.engagement,
                engagementType: filters.engagementType,
                programType: filters.programType,
                accHandler: filters.accHandler,
                engagementStatus: filters.engagementStatus,
                tiering: filters.tiering,
                dateRange: filters.dateRange,
                cohort: filters.cohort,
                courseType: filters.courseType,
                leadStage: filters.leadStage,
                leadSource: filters.leadSource,
                deliveryMode: filters.deliveryMode,
                industryType: filters.industryType,
                search: !searchTerm ? "" : searchTerm,
                pageNo: currPage,

            };
            // console.log("obj-api call", obj);

            dispatch(getAllMasters(obj));
            // console.log("lolipop");
        }
        // console.log("message", message);

    }, [message])

    useEffect(() => {
        if (currPage === 1) {
            // Always update totalMentors on page 1 (including empty results)
            setTotalMentors(allMasters);
        } else if (allMasters?.length > 0) {
            // For pagination, only append if there are new items
            setTotalMentors(prev => [...prev, ...allMasters]);
        }
    }, [allMasters, currPage])

    // console.log("totalMentors", totalMentors?.map((item) => item?.id));  


    return (
        <>
            <LogoutPopup
                setShowLogout={setShowLogout}
                showLogout={showLogout}
            />
            <div className='allMentorPage'>
                <div className='headingCommon'>
                    <div className='leftSideHeading'>
                        <img src="/assets/svg/user.svg" alt="" />
                        <span className='text16n'>All Masters</span>
                    </div>
                    <div className='rightSideHeading'>
                        <Button
                            className={"whiteBtn downloaddata"}
                            iconName={'DownloadIcon'}
                            text={"All Master List"}
                            onClick={downloadData}
                        />
                        {/* <Button iconName={'eye'} text="Master Availability on Campus" className={'BlueBorderButton'} /> */}
                        <Button iconName={'filter'} text={'Advanced Filters'} className={'whiteBtn'} onClick={() => {
                            setshowFilter(true);
                        }} />
                    </div>
                </div>

                <div className='whiteBoxWrapper mt-12'>
                    <div className='whiteBoxHeading'>
                        <h3 className='uppercase text14b'>Basic Filters</h3>
                        <div className='groupTwoBtn'>
                            <Button text={'Clear All Filters'} className={' GreyBorderButton'} onClick={clearAllFilters} />
                            <Button text={'Apply All Filter'} className={'BlueFillButton'} onClick={applyAllFilters} />
                            {/* <span className='text13n pointer' onClick={clearAllFilters} >Clear All Filters</span> */}
                        </div>
                    </div>

                    <div className='whiteBoxBody'>
                        <div className='filterWrapperBlock'>
                            {/* <MultiSelectDropdown label={'Engagement Type'} options={engagementTypeOptions} />
                        <MultiSelectDropdown label={'Program Type'} options={programTypeOptions} />
                        <MultiSelectDropdown label={'Focus Area'} options={focusAreaOptions} />
                        <MultiSelectDropdown label={'Acc. Handler'} options={accHandlerOptions} />
                        <MultiSelectDropdown label={'Engagement Status'} options={engagementStatusOptions} />
                        <MultiSelectDropdown label={'Tiering'} options={tieringOptions} /> */}
                            <SingleSelectDropdown
                                label={'Engagement Type'}
                                options={engagementTypeOptions}
                                selected={engagementTypeOptions.find((opt) => opt.value === filters.engagement)}
                                onChange={(option) => handleFilterChange('engagement', option?.value || '')}
                                placeholder={'Select Engagement Type'}
                                onClear={() => handleFilterChange('engagement', '')}
                            />

                            <SingleSelectDropdown
                                label={'Program Type'}
                                options={programTypeOptions}
                                selected={programTypeOptions.find((opt) => opt.value === filters.programType)}
                                onChange={(option) => handleFilterChange('programType', option?.value || '')}
                                placeholder={'Select Program Type'}
                                onClear={() => handleFilterChange('programType', '')}
                            />

                            <SingleSelectDropdown
                                label={'Focus Area'}
                                options={focusAreaOptions}
                                selected={focusAreaOptions.find((opt) => opt.value === filters.focusAreas)}
                                onChange={(option) => handleFilterChange('focusAreas', option?.value || '')}
                                placeholder={'Select Focus Area'}
                                onClear={() => handleFilterChange('focusAreas', '')}
                            />

                            <SingleSelectDropdown
                                label={'Acc. Handler'}
                                options={accHandlerOptions}
                                selected={accHandlerOptions.find((opt) => opt.value === filters.accHandler)}
                                onChange={(option) => handleFilterChange('accHandler', option?.value || '')}
                                placeholder={'Select Acc. Handler'}
                                onClear={() => handleFilterChange('accHandler', '')}
                            />

                            <SingleSelectDropdown
                                label={'Engagement Status'}
                                options={engagementStatusOptions}
                                selected={engagementStatusOptions.find((opt) => opt.value === filters.engagementStatus)}
                                onChange={(option) => handleFilterChange('engagementStatus', option?.value || '')}
                                placeholder={'Select Engagement Status'}
                                onClear={() => handleFilterChange('engagementStatus', '')}
                            />

                            <SingleSelectDropdown
                                label={'Tiering'}
                                options={tieringOptions}
                                selected={tieringOptions.find((opt) => opt.value === filters.tiering)}
                                onChange={(option) => handleFilterChange('tiering', option?.value || '')}
                                placeholder={'Select Tiering'}
                                onClear={() => handleFilterChange('tiering', '')}
                            />
                            {/* <MultiSelectDropdown label={'Current Location'} options={options} /> */}
                            <CalenderCustom
                                value={filters.dateRange}
                                onChange={(range) => setFilters((prev) => ({
                                    ...prev,
                                    dateRange: range
                                }))} />
                        </div>
                    </div>
                </div>

                <div className="tableWrapper overFlowHeight paginationAddon">
                    <table className="blackTable">
                        <tr className="stickyTable">
                            <th className="blackHeader">
                                <div className="box2col gap10">
                                    <img src="/assets/svg/BlackUserIcon.svg" alt="" />
                                    <p>name</p>
                                </div>
                            </th>
                            <th className="blackHeader">
                                <div className="box2col gap10">
                                    <img src="/assets/svg/hash.svg" alt="" />
                                    <p>Master ID</p>
                                </div>
                            </th>
                            <th className="blackHeader">
                                <div className="box2col gap10">
                                    <img src="/assets/svg/BlackUserIcon.svg" alt="" />
                                    <p>Email ID</p>
                                </div>
                            </th>
                            <th className="blackHeader">
                                <div className="box2col gap10">
                                    <img src="/assets/svg/location.svg" alt="" />
                                    <p> Designation</p>
                                </div>
                            </th>
                            <th className="blackHeader">
                                <div className="box2col gap10">
                                    <img src="/assets/svg/email.svg" alt="" />
                                    <p>Location</p>
                                </div>
                            </th>
                            <th className="blackHeader">
                                <div className="box2col gap10">
                                    <img src="/assets/svg/focusArea.svg" alt="" />
                                    <p>Focus Areas</p>
                                </div>
                            </th>
                            <th className="blackHeader">
                                <div className="box2col gap10">
                                    <img src="/assets/svg/leadSage.svg" alt="" />
                                    <p>Lead Stage</p>
                                </div>
                            </th>
                            <th className="blackHeader">
                                <div className="box2col gap10">
                                    <img src="/assets/svg/engagement.svg" alt="" />
                                    <p>Engagement Type</p>
                                </div>
                            </th>
                            <th className="blackHeader">
                                <div className="box2col gap10">
                                    <img src="/assets/svg/lastActivity.svg" alt="" />
                                    <p>Last Activity ON</p>
                                </div>
                            </th>

                        </tr>

                        {totalMentors?.length > 0
                            ? totalMentors?.map((item, index) => (
                                <tr>
                                    <td className="tableinner">
                                        <div className="box2col gap15" onClick={(e) => navigate("/aboutMaster/" + item?.id)}>
                                            <img
                                                src={"/assets/img/profile.png"}
                                                alt=""
                                                className="profileImg" />
                                            <p className="text-13 pointer nameHover">{item?.title ? item?.title : ""}{" "}
                                                {item?.firstName ? item?.firstName : "---"}{" "}
                                                {item?.lastName ? item?.lastName : ""}</p>
                                        </div>
                                    </td>
                                    <td className="tableinner">
                                        <div className="box2col gap15">
                                            <p className="text-13">MU{item?.masterID ? item?.masterID : ""}</p>
                                        </div>
                                    </td>
                                    <td className="tableinner">
                                        <div className="box2col gap15">
                                            <p className="text-13">{item?.email ? item?.email : ""}</p>
                                        </div>
                                    </td>
                                    <td className="tableinner">
                                        <div className="box2col gap15">
                                            <p className="text-13">{item?.currentDesignation
                                                ? item?.currentDesignation
                                                : item?.currentWorkCRMs?.map((val) => val.currentDesignation)[0] === "" ? "---" : item?.currentWorkCRMs?.map((val) => val.currentDesignation)[0]}</p>
                                        </div>
                                    </td>
                                    <td className="tableinner">
                                        <div className="box2col gap15">
                                            <p className="text-13">{item?.currentLocation ? item?.currentLocation : item?.currentWorkCRMs?.map((val) => val.currentLocation)[0] === "" ? "---" : item?.currentWorkCRMs?.map((val) => val.currentLocation)[0]}</p>
                                        </div>
                                    </td>
                                    <td className="tableinner">
                                        <div className="box2col gap10">
                                            <div className="g-Tag">{item?.focusAreas ? item?.focusAreas : "---"}</div>
                                            {/* <div className="g-Tag">Former CMO </div> */}
                                        </div>
                                    </td>

                                    <td className="tableinner">
                                        <div className="box2col gap15">
                                            <p className="text-13">{item?.leadStage ? item?.leadStage : "---"}</p>
                                        </div>
                                    </td>
                                    <td className="tableinner">
                                        <div className="box2col gap15">
                                            <p className="text-13">{item?.engagementType ? item?.engagementType : item?.EngagementDetailsCRMs?.map((val) => !val.engagementType)[0] ? "---" : item?.EngagementDetailsCRMs?.map((val) => val.engagementType)[0]}</p>
                                        </div>
                                    </td>
                                    <td className="tableinner">
                                        <div className="box2col gap15">
                                            <p className="text-13">{item?.updatedAt
                                                ? new Date(item.updatedAt).toLocaleDateString("en-GB", {
                                                    day: "2-digit",
                                                    month: "short",
                                                    year: "numeric",
                                                })
                                                : "---"}</p>
                                        </div>
                                    </td>
                                </tr>
                            ))
                            : <DatanotFound/>}
                    </table>

                    {!isMastersFetching && allMasters.length > 0 && <div className="ViewMoreButton" style={{ opacity: "0" }} ref={targetRef} >
                        <Button name={'Load More'} noBorder={true} onClick={nextPage} />
                    </div>}

                </div>
            </div>
        </>

    )

}

export default AllMastersList