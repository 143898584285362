import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router-dom';
import Button from '../../Components/Button/Button'
import './MasterDetailStyle.css';
import { clearState,getMasterDetails, masterSliceData } from "../../reducer/masterSlice";
import PageLoader from "../../Components/PageLoader/PageLoader";
import MastersDeatailPopup from "../../Components/Popups/MastersDeatailPopup/MastersDeatailPopup";
import CashDetailPopup from '../../Components/Popups/CashDetailPopup/CashDetailPopup';
import EditDetailPopup from '../../Components/Popups/EditDetailPopup/EditDetailPopup';
import moment from 'moment/moment';
import AddNotePopup from '../../Components/Popups/addNotePopup/addNotePopup';
// import AddNotePopup from '../../Components/Popups/AddNotePopup/AddNotePopup';
import SuccessToast from '../../Components/Toast/SuccessToast'

const MasterDetail = ({showAddDetails,setShowAddDetails,setMasterId}) => {

const [tabActive, setTabActive] = useState(1);
const [accordian, setAccordian] = useState(0);
const [openAccordions, setOpenAccordions] = useState([]);
const [editEngagement, setEditEngagement]= useState(false);
const [session,setSession]=useState('')
const [showAddMasterDetails, setShowAddMasterDetails] = useState(false);
const [showAddNote, setShowAddNote] = useState(false);
const [isShowError, setIsShowError] = useState(false);
const [errMessage, setErrMessage] = useState("");
const {id} = useParams();
if (id) setMasterId(id);
const dispatch = useDispatch();
const {mentorDetails,isFetching, isSuccess, errorMessage}= useSelector(masterSliceData)
const [currFomr, setCurrForm] = useState(1);
const downloadImg=(value)=>{
    window.open(value);
}

//////////////////////////////state////////////////////////////////////////////

const [aliasState, setAliasState] = useState([]);
const [personalInfo,setPersonalInfo ]= useState({
    title : "",
    firstName : "",
    lastName : "",
    alias:"",
    leadSource : "",
    countryCode:"",
    phoneNumber : "",
    email: "",
    linkedin : "",
    twitter : "",
    instagram: "",
    facebook : "",
    focusAreas: "",
    speciality: "",
    doB : "",
    residanceCity: "",
    residanceState:"",
    country : "",
    address : "",
    workCity : "",
    workState : "",
    workCountry : "",
    workAddress : "",  

  })
  
  const [bankDetails,setBankDetails]= useState({
    // accountHolder:"",
    // leads:"",
    bankName:"",
    accountName:"",
    accountNumber:"",
    accountType: "",
    ifscCode: "",
    routingNumber : "",
    gstNumber: "",
    panNumber: "",
    aadharNumber: "",
    // swiftCode:"",

}) 

const [photos,setPhotos]=useState({
    chequeUrl: {
        url: "",
        name: ""
    },
    panCardUrl:{
        url: "",
        name: ""
    },
    aadharCardUrl:{
        url: "",
        name: ""
    },
    otherPhotoUrl:{
        url: "",
        name: ""
    },
    profileUrl:{
        url: "",
        name: ""
    }
})
const [photos2,setPhotos2]=useState({
    chequeUrl: {
        url: "",
        name: ""
    },
    panCardUrl:{
        url: "",
        name: ""
    },
    aadharCardUrl:{
        url: "",
        name: ""
    },
    otherPhotoUrl:{
        url: "",
        name: ""
    }
  })

const [educationDetails, setEducationDetails] = useState({
    undergradCollege:"",
    undergradDegree:"",
    undergradSpecialization:"",
    undergradYear:"",
    postgradCollege:"",
    postgradDegree:"",
    postgradSpecialization:"",
    postgradYear:"",
    phdCollege: "",
    phdArea:"",
    phdYear:"",

})


const [currentWork, setCurrentWork] = useState([{
    currentDesignation: "",
    currentCompany: "",
    currentLocation: ""
}]);

const [previousWork, setPreviousWork] = useState([{
    previousDesignation: "",
    previousCompany: "",
    previousLocation: "",
}]);

const [otherEducation, setOtherEducation]=useState([{
    otherCollege:"",
    degree:"",
    specialization:"",
    year:"",
}])
 
const [otherEducationCount, setOtherEducationCount] = useState([0]);
const [currentWorkCount, setCurrentWorkCount] = useState([0]);
const [peviousWorkCount, setPrevioustWorkCount] = useState([0]);

/////////////////////////////////////////////////////////////////////////

const toggleAccordion = (index) => {
    setOpenAccordions((prev) =>
        prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
};

useEffect(()=>{
    if(id)dispatch(getMasterDetails(id));

},[])

useEffect(() => {
    setPersonalInfo((prevState) => ({
      ...prevState,
      title : mentorDetails[0]?.title,
      alias: (typeof mentorDetails[0]?.alias === 'string' ? [...mentorDetails[0]?.alias?.split(',')] : []),
      firstName : mentorDetails[0]?.firstName,
      lastName : mentorDetails[0]?.lastName,
      leadSource : mentorDetails[0]?.leadSource,
      countryCode: mentorDetails[0]?.countryCode,
      phoneNumber : mentorDetails[0]?.phoneNumber,
      email: mentorDetails[0]?.email,
      linkedin : mentorDetails[0]?.linkedin,
      twitter : mentorDetails[0]?.twitter,
      instagram: mentorDetails[0]?.instagram,
      facebook : mentorDetails[0]?.facebook,
      focusAreas: mentorDetails[0]?.focusAreas,
      speciality: mentorDetails[0]?.speciality,
      doB : mentorDetails[0]?.doB,
      residanceCity: mentorDetails[0]?.residanceCity,
      residanceState:mentorDetails[0]?.residanceState ,
      country : mentorDetails[0]?.country,
      address : mentorDetails[0]?.address,
      workCity : mentorDetails[0]?.workCity,
      workState : mentorDetails[0]?.workState,
      workCountry : mentorDetails[0]?.workCountry,
      workAddress : mentorDetails[0]?.workAddress,  
    }));
    setBankDetails((prevState)=>({
        ...prevState,
    // accountHolder:mentorDetails[0]?.BankDetailsCRMs[0]?.accountHolder,
    // leads:mentorDetails[0]?.BankDetailsCRMs[0]?.leads,
    bankName:mentorDetails[0]?.BankDetailsCRMs[0]?.bankName,
    accountName:mentorDetails[0]?.BankDetailsCRMs[0]?.accountName,
    accountNumber:mentorDetails[0]?.BankDetailsCRMs[0]?.accountNumber,
    accountType: mentorDetails[0]?.BankDetailsCRMs[0]?.accountType,
    ifscCode: mentorDetails[0]?.BankDetailsCRMs[0]?.ifscCode,
    routingNumber : mentorDetails[0]?.BankDetailsCRMs[0]?.routingNumber,
    gstNumber: mentorDetails[0]?.BankDetailsCRMs[0]?.gstNumber,
    panNumber: mentorDetails[0]?.BankDetailsCRMs[0]?.panNumber,
    aadharNumber: mentorDetails[0]?.BankDetailsCRMs[0]?.aadharNumber,
    // swiftCode: mentorDetails[0]?.BankDetailsCRMs[0]?.swiftCode,

    })) 
    setEducationDetails((prevState) => ({
        ...prevState,
        undergradCollege:mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradCollege,
        undergradDegree:mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradDegree,
        undergradSpecialization:mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradSpecialization,
        undergradYear:mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradYear,
        postgradCollege:mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradCollege,
        postgradDegree:mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradDegree,
        postgradSpecialization:mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradSpecialization,
        postgradYear:mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradYear,
        phdCollege: mentorDetails[0]?.eductionDetailsCRMs[0]?.phdCollege,
        phdArea:mentorDetails[0]?.eductionDetailsCRMs[0]?.phdArea,
        phdYear:mentorDetails[0]?.eductionDetailsCRMs[0]?.phdYear,  
      }));      
    setPhotos(prev => ({
        ...prev,
        ["profileUrl"]: {url: mentorDetails[0]?.profileUrl,name: "profileUrl"},
        ["otherPhotoUrl"]: {url: mentorDetails[0]?.BankDetailsCRMs[0]?.otherPhotoUrl,name: "otherPhotoUrl"},
        ["aadharCardUrl"]: {url: mentorDetails[0]?.BankDetailsCRMs[0]?.aadharCardUrl,name: "aadharCardUrl"},
        ["panCardUrl"]: {url: mentorDetails[0]?.BankDetailsCRMs[0]?.panCardUrl,name: "panCardUrl"},
        ["chequeUrl"]: {url: mentorDetails[0]?.BankDetailsCRMs[0]?.chequeUrl,name: "chequeUrl"}

    }))
    setOtherEducation(mentorDetails[0]?.OtherEductionDetailsCRMs)
    if(mentorDetails[0]?.OtherEductionDetailsCRMs?.length > 0) {
        let arr = otherEducationCount;
        arr[mentorDetails[0]?.OtherEductionDetailsCRMs?.length - 1] = 0;
        setOtherEducationCount(arr);
    }
    
    setCurrentWork(mentorDetails[0]?.currentWorkCRMs)
    if(mentorDetails[0]?.currentWorkCRMs?.length > 0) {
        let arr = otherEducationCount;
        arr[mentorDetails[0]?.currentWorkCRMs?.length - 1] = 0;
        setCurrentWorkCount(arr);
    }
    setPreviousWork(mentorDetails[0]?.previousWorkCRMs)
    if(mentorDetails[0]?.currentWorkCRMs?.length > 0) {
        let arr = otherEducationCount;
        arr[mentorDetails[0]?.currentWorkCRMs?.length - 1] = 0;
        setPrevioustWorkCount(arr);
    }
}, [mentorDetails]);

const handleIndexChange= (i) => {
    if(accordian === i) setAccordian("")
    else setAccordian(i);
}

const obj={
    title : personalInfo.title,
    firstName : personalInfo.firstName,
    lastName : personalInfo.lastName,
    alias : aliasState?.join(','),
    leadSource : personalInfo.leadSource,
    countryCode: personalInfo.countryCode,
    phoneNumber : personalInfo.phoneNumber,
    email: personalInfo.email,
    linkedin : personalInfo.linkedin,
    twitter : personalInfo.twitter,
    instagram: personalInfo.instagram,
    facebook : personalInfo.facebook,
    focusAreas: personalInfo.focusAreas,
    speciality: personalInfo.speciality,
    doB : personalInfo.doB,
    residanceCity: personalInfo.residanceCity,
    residanceState:personalInfo.residanceState,
    country : personalInfo.country,
    address : personalInfo.address,
    workCity : personalInfo.workCity,
    workState:personalInfo.workState ,
    workCountry : personalInfo.workCountry,
    workAddress : personalInfo.workAddress,
   profileUrl:photos.profileUrl.url,
   educationDetails:{
   undergradCollege:educationDetails.undergradCollege,
   undergradDegree:educationDetails.undergradDegree,
   undergradSpecialization:educationDetails.postgradSpecialization,
   undergradYear:educationDetails.undergradYear,
   postgradCollege:educationDetails.postgradCollege,
   postgradDegree:educationDetails.postgradDegree,
   postgradSpecialization:educationDetails.postgradSpecialization,
   postgradYear:educationDetails.postgradYear,
   phdCollege: educationDetails.phdCollege,
   phdArea:educationDetails.phdArea,
   phdYear:educationDetails.phdYear, 
   },
   bankDetails:{
    //    accountHolder:bankDetails?.accountHolder,   
    //    leads:bankDetails?.leads,
       bankName:bankDetails?.bankName,
       accountName:bankDetails?.accountName,
       accountNumber:bankDetails?.accountNumber,
       accountType: bankDetails?.accountType,
       ifscCode: bankDetails?.ifscCode,
    //    swiftCode:bankDetails?.swiftCode,
       routingNumber : bankDetails?.routingNumber,
       gstNumber: bankDetails?.gstNumber,
       panNumber: bankDetails?.panNumber,
       aadharNumber:bankDetails?.aadharNumber,
       chequeUrl: photos.chequeUrl.url,
       panCardUrl:photos.panCardUrl.url,
       aadharCardUrl:photos.aadharCardUrl.url,
       otherPhotoUrl:photos.otherPhotoUrl.url,
   },   
   currentWork,
   previousWork,
   otherEducation,
  
  }

//   console.log("MasterDetails-Object",obj);
    // console.log(otherEducation,"OTHERS")
// console.log("PersonalInfo",personalInfo,bankDetails,photos,educationDetails,mentorDetails[0]?.workAddress);
// console.log(mentorDetails[0]?.BankDetailsCRMs[0]?.leads);

function formatDate(dateString) {
    const dateParts = !dateString?"---":dateString?.split("-");
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
  
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  useEffect(() => {
    if (isSuccess) {
        console.log("errMessage", errMessage);
      dispatch(clearState());
      setIsShowError(true);
     setErrMessage(errorMessage);
     setShowAddDetails(false)
      
    }
  }, [isSuccess]);

  return (
    <>
    {isFetching && <PageLoader/>}
    <SuccessToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
    <MastersDeatailPopup showAddDetails={showAddDetails} setShowAddDetails={setShowAddDetails}
    otherEducation={otherEducation} setOtherEducation={setOtherEducation}
    previousWork={previousWork} setPreviousWork={setPreviousWork}
    currentWork={currentWork} setCurrentWork={setCurrentWork}
    educationDetails={educationDetails} setEducationDetails={setEducationDetails}
    photos={photos} setPhotos={setPhotos}
    bankDetails={bankDetails} setBankDetails={setBankDetails}
    personalInfo={personalInfo} setPersonalInfo={setPersonalInfo}
    otherEducationCount={otherEducationCount}  setOtherEducationCount={setOtherEducationCount}
    currentWorkCount={currentWorkCount} setCurrentWorkCount={setCurrentWorkCount} 
    peviousWorkCount={peviousWorkCount} setPrevioustWorkCount={setPrevioustWorkCount}
    photos2={photos2} setPhotos2={setPhotos2}
    object={obj} id={id} aliasState={aliasState} setAliasState={setAliasState}
    currFomr={currFomr}
    setCurrForm={setCurrForm}
    />
    <CashDetailPopup
     setShowAddMasterDetails={setShowAddMasterDetails}
     showAddMasterDetails={showAddMasterDetails}
     id={id}
    />
    <AddNotePopup
    showAddNote={showAddNote}
    setShowAddNote={setShowAddNote}
    id ={id}

    />

    <EditDetailPopup 
    setEditEngagement={setEditEngagement}
    editEngagement={editEngagement}
    session={session}
    id={id}
    />

    <div className='MasterWrapper'>
         <div className="masterDetailOuter flexbox gap10 mb-10">
            <img className='square134' src={mentorDetails[0]?.profileUrl? mentorDetails[0]?.profileUrl: "/assets/img/profile.png"} alt="" />
            <div className="masterDetail">
                <div className="masterHeader mb-15">
                    <div className="box2col gap10">
                        <p className="text-18">{mentorDetails[0]?.title ? mentorDetails[0]?.title :"" } {mentorDetails[0]?.firstName ? mentorDetails[0]?.firstName :"---" } {mentorDetails[0]?.lastName ? mentorDetails[0]?.lastName :""}</p>
                       { personalInfo?.alias?.length > 0 && personalInfo?.alias?.map((item,index)=>(<div className="box2col gap5">
                            {/* <div className="b-Tag">{mentorDetails[0]?.firstName ? mentorDetails[0]?.firstName :"---" }</div> */}
                            <div className="b-Tag" key={index}>{item!=="" ? item :"N/A"}</div>
                            {/* <div className="b-Tag">Capt</div> */}
                        </div>))}
                    </div>
                    <div className="flexbox gap15">
                       { mentorDetails[0]?.facebook && <img className='pointer' src="/assets/svg/FacebookIcon.svg" alt="" onClick={() => (mentorDetails[0]?.facebook && window.open(mentorDetails[0]?.facebook))}/>}
                       { mentorDetails[0]?.twitter && <img className='pointer' src="/assets/svg/TwitterIcon.svg" alt="" onClick={() => (mentorDetails[0]?.twitter && window.open(mentorDetails[0]?.twitter))}/>}
                       { mentorDetails[0]?.instagram && <img className='pointer' src="/assets/svg/instagramIcon.svg" alt="" onClick={() => (mentorDetails[0]?.instagram && window.open(mentorDetails[0]?.instagram))}/>}
                       { mentorDetails[0]?.linkedin && <img className='pointer' src="/assets/svg/LinkedinIcon.svg" alt="" onClick={() => (mentorDetails[0]?.linkedin && window.open(mentorDetails[0]?.linkedin))}/>}
                    </div>
                </div>

                {/* <div className="flexbox twoPieceBox">
                    <div className="box2col gap5 mb-10">
                        <p className="text-13">Focus Areas:</p>
                        <div className="g-Tag"><b>{mentorDetail
                            s[0]?.focusAreas ? mentorDetails[0]?.focusAreas :"---" }</b></div>
                    </div>

                    <div className="box2col gap5 mb-10">
                        <p className="text-13">Additional Area of Specialty:</p>
                        <p className="g-Tag">{mentorDetails[0]?.speciality ? mentorDetails[0]?.speciality :"---" }</p>
                    </div>
                </div> */}


           { mentorDetails[0]?.BankDetailsCRMs.length > 0 && 
                 mentorDetails[0]?.BankDetailsCRMs?.map((item,index)=>(
                <div className="contactDetails flexbox mb-10 contacttopcard">
                    <p className="text-13">Email ID: <b>{mentorDetails[0]?.email ? mentorDetails[0]?.email :"---" }</b></p>
                    <p className="text-13 ">Account Holder: <b>{item?.accountHolder !=="" ? item?.accountHolder:"N/A" }</b></p>
                </div>))
                }
                <div className="contactDetails flexbox mb-10 contacttopcard">
                    <p className="text-13">Phone Number: <b>{mentorDetails[0]?.countryCode ? mentorDetails[0]?.countryCode :"" }{" "}{mentorDetails[0]?.phoneNumber ? mentorDetails[0]?.phoneNumber :"---" }</b></p>
                    <p className="text-13">Lead Source: <b>{mentorDetails[0]?.leadSource!==""? mentorDetails[0]?.leadSource:"N/A"}</b></p>
                </div>
                    <div className="contactDetails flexbox contacttopcard2 mb-10">
                        <div className="box2col gap5">
                            <p className="text-13">Focus Areas:</p>
                            <div className="g-Tag"><b>{mentorDetails[0]?.focusAreas ? mentorDetails[0]?.focusAreas :"---" }</b></div>
                        </div>
                     <p className="text-13">Date Of Birth: <b>{mentorDetails[0]?.doB ? formatDate(mentorDetails[0]?.doB) :"N/A" }</b></p>
                </div>
                <div className="contactDetails flexbox contacttopcard">   
                </div>
                <div className="flexbox twoPieceBox">
                    <div className="box2col gap5 mb-10">
                        <p className="text-13">Areas of Specialty: </p>
                        <p className="text-13"><b>{mentorDetails[0]?.speciality ? mentorDetails[0]?.speciality :"---" }</b></p>
                    </div>
                </div>
            </div>
         </div>
         <div className="flexbox mastersTab">
            <div className={`box2col Tab ${tabActive === 1 && 'active'}`} onClick={()=> setTabActive(1)}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.70001 1.68666L2.68668 4.30666C1.40001 5.14666 1.40001 7.02666 2.68668 7.86666L6.70001 10.4867C7.42001 10.96 8.60668 10.96 9.32668 10.4867L13.32 7.86666C14.6 7.02666 14.6 5.15333 13.32 4.31333L9.32668 1.69333C8.60668 1.21333 7.42001 1.21333 6.70001 1.68666Z" stroke="#A3A3A3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.75325 8.72L3.74658 11.8467C3.74658 12.6933 4.39992 13.6 5.19992 13.8667L7.32658 14.5733C7.69325 14.6933 8.29992 14.6933 8.67325 14.5733L10.7999 13.8667C11.5999 13.6 12.2532 12.6933 12.2532 11.8467V8.75333" stroke="#A3A3A3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.2666 10V6" stroke="#A3A3A3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="text-13">Education & Work</p>
            </div>
            <span className="divider"></span>
            <div className={`box2col Tab ${tabActive === 2 && 'active'}`} onClick={()=> setTabActive(2)}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6666 11.16V3.11333C14.6666 2.31333 14.0133 1.71999 13.2199 1.78666H13.1799C11.7799 1.90666 9.65325 2.61999 8.46659 3.36666L8.35325 3.43999C8.15992 3.55999 7.83992 3.55999 7.64659 3.43999L7.47992 3.33999C6.29325 2.59999 4.17325 1.89333 2.77325 1.77999C1.97992 1.71333 1.33325 2.31333 1.33325 3.10666V11.16C1.33325 11.8 1.85325 12.4 2.49325 12.48L2.68659 12.5067C4.13325 12.7 6.36659 13.4333 7.64659 14.1333L7.67325 14.1467C7.85325 14.2467 8.13992 14.2467 8.31325 14.1467C9.59325 13.44 11.8333 12.7 13.2866 12.5067L13.5066 12.48C14.1466 12.4 14.6666 11.8 14.6666 11.16Z" stroke="#A3A3A3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 3.66V13.66" stroke="#A3A3A3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.16675 5.66H3.66675" stroke="#A3A3A3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.66675 7.66H3.66675" stroke="#A3A3A3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <p class="text-13">Address and Bank Details</p>
            </div>
            <span className="divider"></span>
            <div className={`box2col Tab ${tabActive === 3 && 'active'}`} onClick={()=> setTabActive(3)}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00008 8.00001C9.84103 8.00001 11.3334 6.50763 11.3334 4.66668C11.3334 2.82573 9.84103 1.33334 8.00008 1.33334C6.15913 1.33334 4.66675 2.82573 4.66675 4.66668C4.66675 6.50763 6.15913 8.00001 8.00008 8.00001Z" stroke="#A3A3A3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667" stroke="#A3A3A3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="text-13">Master Engagement</p>
            </div>

            <span className="divider"></span>
            <div className={`box2col Tab ${tabActive === 4 && 'active'}`} onClick={()=> setTabActive(4)}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00008 8.00001C9.84103 8.00001 11.3334 6.50763 11.3334 4.66668C11.3334 2.82573 9.84103 1.33334 8.00008 1.33334C6.15913 1.33334 4.66675 2.82573 4.66675 4.66668C4.66675 6.50763 6.15913 8.00001 8.00008 8.00001Z" stroke="#A3A3A3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667" stroke="#A3A3A3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="text-13">Additional Notes</p>
            </div>
         </div>
         <div className={`masterTabDetail mt-10 ${tabActive === 1 && 'active'}`}>
            <div className={`accordianWrapper mb-10 ${openAccordions.includes(1) ? "active" : ""}`} onClick={()=> toggleAccordion(1)}>
                <div className="accoedianQuestion flexbox blackHead">
                    <p className="text-13 text-white">Education Details</p>
                    <img src="/assets/svg/ArrowUp.svg" alt="" />
                </div>
                <div className="accordianAnswer">
                        <p className="text-13 mb-10">Undergrad College: <b>{mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradCollege? mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradCollege:"N/A"}</b></p>
                    <div className="box2col mb-10">
                        <p className="text-13 width33">Undergrad Degree: <b>{mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradDegree? mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradDegree:"N/A"}</b></p>
                    <p className="text-13 width33">Undergrad Specialization: <b>{mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradSpecialization? mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradSpecialization:"N/A"}</b></p>
                        <p className="text-13 width33">Undergrad Year: <b>{mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradYear? mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradYear:"N/A"}</b></p>
                        {/* <p className="text-13 mb-15">Undergrad College: <b>{mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradCollege? mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradCollege:"N/A"}</b></p> */}
                    </div>
                        <p className="text-13 width33 mb-10">Postgrad College: <b>{mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradCollege? mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradCollege:"N/A"}</b></p>
                    <div className="box2col mb-10">
                        <p className="text-13 width33">Postgrad Degree: <b>{mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradDegree? mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradDegree:"N/A"}</b></p>
                        <p className="text-13 width33">Postgrad Specialization: <b>{mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradSpecialization? mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradSpecialization:"N/A"}</b></p>
                        <p className="text-13 width33">Postgrad Year: <b>{mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradYear? mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradYear:"N/A"}</b></p>
                    </div>
                    {  mentorDetails?.length > 0 && mentorDetails[0]?.OtherEductionDetailsCRMs.length >0 && 
                        mentorDetails[0]?.OtherEductionDetailsCRMs?.map((item,index)=>(  
                        <>   
                         {item?.otherCollege  && <div>
                        <p className="text-13 width33 mb-10">Other College - {`${index+1}`} : <b>{item?.otherCollege?item?.otherCollege:"N/A"}</b></p>
                        <div className="box2col mb-10">
                        <p className="text-13 width33">Other Degree - {`${index+1}`} :<b>{item?.degree? item?.degree:"N/A"}</b></p>
                        <p className="text-13 width33">Other Specialization - {`${index+1}`} :<b>{item?.specialization?item?.specialization:"N/A"}</b></p>
                        <p className="text-13 width33">Other Year - {`${index+1}`} :<b>{item?.year?item?.year:"N/A"}</b></p>
                    </div>
                    </div> }
                    </>
                    ))
                }
                    <div className="box2col mb-10">
                    <p className="text-13 width33 ">PhD College: <b>{mentorDetails[0]?.eductionDetailsCRMs[0]?.phdCollege? mentorDetails[0]?.eductionDetailsCRMs[0]?.phdCollege:"N/A"}</b></p>
                        <p className="text-13 width33">PhD Specialization: <b>{mentorDetails[0]?.eductionDetailsCRMs[0]?.phdArea? mentorDetails[0]?.eductionDetailsCRMs[0]?.phdArea:"N/A"}</b></p>
                        <p className="text-13 width33">PhD Year: <b>{mentorDetails[0]?.eductionDetailsCRMs[0]?.phdYear? mentorDetails[0]?.eductionDetailsCRMs[0]?.phdYear:"N/A"}</b></p>
                    </div>
                </div>
            </div>
            <div className={`accordianWrapper mb-10 ${openAccordions.includes(2) ? "active" : ""}`} onClick={()=> toggleAccordion(2)}>
                
                <div className="accoedianQuestion flexbox blackHead">
                    <p className="text-13 text-white">Work Experience</p>
                    <img src="/assets/svg/ArrowUp.svg" alt="" />
                </div>


                { mentorDetails?.length > 0 && mentorDetails[0]?.currentWorkCRMs.length >0 && 
                        mentorDetails[0]?.currentWorkCRMs?.map((item,index)=>(
                        <div className="accordianAnswer">
                            
                        <p className="accordianHead wrk2 mb-10">Current Work</p>

                        <div className="box2col">
                            <p className="text-13 width33">Current Designation {`${index+1}`} :<b> {item?.currentDesignation ? item?.currentDesignation : "N/A"}</b></p>
                            <p className="text-13 width33">Current Company {`${index+1}`}: <b>{item?.currentCompany ? item?.currentCompany : "N/A"}</b></p>
                            <p className="text-13 width33">Current Location {`${index+1}`}: <b>{item?.currentLocation ? item?.currentLocation : "N/A"}</b></p>
                        </div>

                        <p className="accordianHead wrk2 mb-10 mt-20">Previous Work</p>

                        { mentorDetails?.length > 0 && mentorDetails[0]?.previousWorkCRMs?.length >0 && 
                        mentorDetails[0]?.previousWorkCRMs?.map((item,index)=>(
                            <div className="box2col">
                                <p className="text-13 width33">Previous Designation {`${index+1}`}: <b>{item?.previousDesignation ? item?.previousDesignation : "N/A"}</b></p>
                                <p className="text-13 width33">Previous Company {`${index+1}`}: <b>{item?.previousCompany ? item?.previousCompany : "N/A"}</b></p>
                                <p className="text-13 width33">Previous Location {`${index+1}`}: <b>{item?.previousLocation ? item?.previousLocation : "N/A"}</b></p>
                            </div>
                        ))}
                        </div>
                    ))}            

            </div>
         </div>
         <div className={`masterTabDetail mt-10 ${tabActive === 2 && 'active'}`}>
            <div className={`accordianWrapper mb-10 ${openAccordions.includes(3) ? "active" : ""}`} onClick={()=> toggleAccordion(3)}>
                <div className="accoedianQuestion flexbox blackHead">
                    <p className="text-13 text-white">Address</p>
                    <img src="/assets/svg/ArrowUp.svg" alt="" />    
                </div>
                <div className="accordianAnswer">
                    <div className="box2col mb-25 flexstart">
                        <p className="text-13 width50">Residence City: <b>{mentorDetails[0]?.residanceCity ? mentorDetails[0]?.residanceCity :"N/A" }</b></p>
                        <p className="text-13 width50">Residence state: <b>{mentorDetails[0]?.residanceState ? mentorDetails[0]?.residanceState :"N/A" }</b></p>
                        <p className="text-13 width50">Residence Country: <b>{mentorDetails[0]?.country ? mentorDetails[0]?.country :"N/A" }</b></p>
                        <p className="text-13 width50">Residence Street Address: <b>{mentorDetails[0]?.address ? mentorDetails[0]?.address :"N/A" }</b></p>
                    </div>
                    <div className="box2col mb-25 flexstart">
                        <p className="text-13 width50">Work City: <b>{mentorDetails[0]?.workCity ? mentorDetails[0]?.workCity :"N/A" }</b></p>
                        <p className="text-13 width50">Work State: <b>{mentorDetails[0]?.workState ? mentorDetails[0]?.workState :"N/A" }</b></p>
                        <p className="text-13 width50">Work Country: <b>{mentorDetails[0]?.workCountry ? mentorDetails[0]?.workCountry :"N/A" }</b></p>
                        <p className="text-13 width50">Work Street Address: <b>{mentorDetails[0]?.workAddress ? mentorDetails[0]?.workAddress :"N/A" }</b></p>
                    </div>
                </div>    
            </div>

            <div className={`accordianWrapper mb-10 ${openAccordions.includes(4) ? "active" : ""}`} onClick={()=> toggleAccordion(4)}>
                <div className="accoedianQuestion flexbox blackHead">
                    <p className="text-13 text-white">Bank Details</p>
                    <img src="/assets/svg/ArrowUp.svg" alt="" />
                </div>
                {mentorDetails[0]?.BankDetailsCRMs.length > 0 && 
                 mentorDetails[0]?.BankDetailsCRMs?.map((item,index)=>(
                    <>
                    <div className="accordianAnswer">
                    {/* <p className="accordianHead mb-10">{item?.accountHolder !=="" ? item?.accountHolder : "" }</p>
                    <p className="accordianHead mb-10">{item?.leads !=="" ? item?.leads : "" }</p> */}
                    <div className="box2col mb-15">
                        {/* <p className="text-13 width50">Account Holder: <b>{item?.accountHolder !=="" ? item?.accountHolder:"N/A" }</b></p>
                        <p className="text-13 width50">Account Lead: <b>{item?.leads!==""?item?.leads:"N/A"}</b></p> */}
                    </div>
                    <div className="box2col mb-15">
                        <p className="text-13 width50">Bank name: <b>{item?.bankName !=="" ? item?.bankName:"N/A" }</b></p>
                        <p className="text-13 width50">Account Name: <b>{item?.accountName!==""?item?.accountName:"N/A"}</b></p>
                    </div>
                    <div className="box2col mb-15">
                        <p className="text-13 width50">A/C Number: <b>{item?.accountNumber!==""?item?.accountNumber:"N/A"}</b></p>
                        <p className="text-13 width50">Account Type: <b>{item?.accountType!==""?item?.accountType:"N/A"}</b></p>
                    </div>
                    <div className="box2col mb-15">
                        <p className="text-13 width50">IFSC Code / Swift Code: <b>{item?.ifscCode !==""?item?.ifscCode:"N/A" }</b></p>       
                        <p className="text-13 width50">Routing Number: <b>{item?.routingNumber!==""?item?.routingNumber:"N/A"}</b></p>
                    </div>
                    <div className="box2col mb-15">
                        <p className="text-13 width50">GST Number: <b>{item?.gstNumber!==""?item?.gstNumber:"N/A"}</b></p>
                        <p className="text-13 width50">PAN Number: <b>{item?.panNumber!==""?item?.panNumber:"N/A"}</b></p>
                    </div>
                    <div className="box2col mb-15">
                    <p className="text-13 mb-15">Aadhar Number: <b>{item?.aadharNumber!=="" ?item?.aadharNumber:"N/A"}</b></p>
                    {/* <p className="text-13 width50">Swift Code: <b>{!item?.swiftCode ? "N/A" : item?.swiftCode}</b></p> */}

                    </div>
                    <div className="box2col mb-10 width66">
                       <div className="box2col gap5" onClick={()=>{downloadImg(item?.aadharCardUrl)}}>
                           <p className="text-13">Aadhar Card: </p>
                           <div className={item?.aadharCardUrl ? "g-Tag" :"grey-Tag"}>{item?.aadharCardUrl? "Download" : "N/A"}
                                <img src="/assets/svg/DownloadIcon.svg" alt="" />
                           </div>
                       </div>
                       <div className="box2col gap5" onClick={()=>{downloadImg(item?.chequeUrl)}}>
                           <p className="text-13">Cancelled Cheque: </p>
                           <div className={item?.chequeUrl ? "g-Tag" :"grey-Tag"}>{item?.chequeUrl? "Download" : "N/A"}
                                <img src="/assets/svg/DownloadIcon.svg" alt="" />
                           </div>
                       </div>
                       
                    </div>
                    <div className="box2col mb-15 width66">
                       <div className="box2col gap5" onClick={()=>{downloadImg(item?.panCardUrl)}}>
                           <p className="text-13">PAN Card: </p>
                           <div className={item?.panCardUrl ? "g-Tag" :"grey-Tag"}>{item?.panCardUrl? "Download" : "N/A"}
                                <img src="/assets/svg/DownloadIcon.svg" alt="" />
                           </div>
                       </div>
                       <div className="box2col gap5" onClick={()=>{downloadImg(item?.otherPhotoUrl)}}>
                           <p className="text-13">Other Document: </p>
                           <div className={item?.otherPhotoUrl ? "g-Tag" :"grey-Tag"}>{item?.otherPhotoUrl? "Download" : "N/A"}
                                <img src="/assets/svg/DownloadIcon.svg" alt="" />
                           </div>
                       </div>
                       
                    </div>
                </div>
                    </>
                 ))
                }
            </div>
         </div>
         <div className={`masterTabDetail pd0 mt-10 ${tabActive === 3 && 'active'}`}>
            <div className="masterEngaement flexbox">
                <p className="text-18">Master Engagement</p>
                <Button className={'blackBtn'} iconName={'AddIcon'} text={'Add Engagement'} onClick={()=>setShowAddMasterDetails(true)}/>
            </div>
            {mentorDetails[0]?.EngagementDetailsCRMs?.length > 0 && 
                 mentorDetails[0]?.EngagementDetailsCRMs?.map((item,index)=>(
                    <>
                 <div className="accordianInner" onClick={()=> handleIndexChange(index)}>
                <div className={`accordianQuestionWrapper flexbox ${accordian === index && 'active'}`}>
                    <div className="deadLine">
                        <p className="text-12">{formatDate(item?.date.split("/").join("-"))}<br /> <b>Start Date</b></p>
                    </div>
                    <div className="accoedianQuestion flexbox g-Tab">

                            <div className="boxWrapData flexbox">
                                <p class="text-16">{item?.sessionType}</p>
                                <img src="/assets/svg/ArrowUpBLack.svg" className='arrowBox' alt="" ></img>
                            </div>

                            { item?.sessionType==='Teaching' &&
                                <div className="accordianAnswer">
                                    <div className="floatingEdit" onClick={()=> {setEditEngagement(true);setSession(item)}}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="#737373"/>
                                            <path d="M8.49984 17.6901C7.88984 17.6901 7.32984 17.4701 6.91984 17.0701C6.42984 16.5801 6.21984 15.8701 6.32984 15.1201L6.75984 12.1101C6.83984 11.5301 7.21984 10.7801 7.62984 10.3701L15.5098 2.49006C17.4998 0.500059 19.5198 0.500059 21.5098 2.49006C22.5998 3.58006 23.0898 4.69006 22.9898 5.80006C22.8998 6.70006 22.4198 7.58006 21.5098 8.48006L13.6298 16.3601C13.2198 16.7701 12.4698 17.1501 11.8898 17.2301L8.87984 17.6601C8.74984 17.6901 8.61984 17.6901 8.49984 17.6901ZM16.5698 3.55006L8.68984 11.4301C8.49984 11.6201 8.27984 12.0601 8.23984 12.3201L7.80984 15.3301C7.76984 15.6201 7.82984 15.8601 7.97984 16.0101C8.12984 16.1601 8.36984 16.2201 8.65984 16.1801L11.6698 15.7501C11.9298 15.7101 12.3798 15.4901 12.5598 15.3001L20.4398 7.42006C21.0898 6.77006 21.4298 6.19006 21.4798 5.65006C21.5398 5.00006 21.1998 4.31006 20.4398 3.54006C18.8398 1.94006 17.7398 2.39006 16.5698 3.55006Z" fill="#737373"/>
                                            <path d="M19.8501 9.82978C19.7801 9.82978 19.7101 9.81978 19.6501 9.79978C17.0201 9.05978 14.9301 6.96978 14.1901 4.33978C14.0801 3.93978 14.3101 3.52978 14.7101 3.40978C15.1101 3.29978 15.5201 3.52978 15.6301 3.92978C16.2301 6.05978 17.9201 7.74978 20.0501 8.34978C20.4501 8.45978 20.6801 8.87978 20.5701 9.27978C20.4801 9.61978 20.1801 9.82978 19.8501 9.82978Z" fill="#737373"/>
                                        </svg>
                                    </div>
                                <div className="box2col mb-15">
                                </div>
                                <div className="box2col mb-15">
                                    <p className="text-13 width33">Programme: <b>{item?.programme ? item?.programme :"N/A"}</b></p>
                                    <p className="text-13 width33">Cohort: <b>{item?.cohort ? item?.cohort :"N/A"}</b></p>
                                    <p className="text-13 width33">Course Name: <b>{item?.courseName ? item?.courseName :"N/A"}</b></p>
                                </div>
                                <div className="box2col mb-15">
                                    <p className="text-13 width33">Course Term: <b>{item?.courseTerm ? item?.courseTerm :"N/A"}</b></p>
                                    <p className="text-13 width33">Course Type: <b>{item?.courseType ? item?.courseType :"N/A"}</b></p>
                                    <p className="text-13 width33">Teaching Dates: <b>{item?.date ? formatDate(item?.date.split("/").join("-")) :"N/A"}</b></p>
                                </div>
                                <div className="box2col mb-15">
                                    <p className="text-13 width33">Credits & Duration: <b>{item?.creditsDuration ? item?.creditsDuration :"N/A"}</b></p>
                                    <p className="text-13 width33">No. of Students enrolled: <b>{item?.studentNo ? item?.studentNo :"N/A"}</b></p>
                                    <p className="text-13 width33">Delivery Mode : <b>{item?.deliveryMode ? item?.deliveryMode :"N/A"}</b></p>
                                </div>
                                <div className="box2col mb-15">
                                <p className="text-13 width33">Class Coordinator/ T.A name: <b>{item?.classCoordinate ? item?.classCoordinate :"N/A"}</b></p>
                                <p className="text-13 width33">Overall Feedback (on 5): <b>{item?.overAllFeedback ? item?.overAllFeedback :"N/A"}</b></p>
                                <p className="text-13 width33">Course Owner : <b>{item?.courseOwner ? item?.courseOwner :"N/A"}</b></p>
                                </div>
                                <div className="classDivider mb-15"></div>
                                <div className="box2col mb-10">
                                <div className="box2col gap5">
                                    <p className="text-13">Course Outline: </p>
                                    <div className={item?.courseOutline ? "g-Tag" :"grey-Tag"} onClick={()=>{downloadImg(item?.courseOutline)}}>{item?.courseOutline? "Download" : "N/A"} 
                                            <img src="/assets/svg/DownloadIcon.svg" alt="" />
                                    </div>
                                </div>
                                <div className="box2col gap5">
                                    <p className="text-13">LOE: </p>
                                    <div className={item?.LOE ? "g-Tag" :"grey-Tag"} onClick={()=>{downloadImg(item?.LOE)}} >{item?.LOE? "Download" : "N/A"} 
                                            <img src="/assets/svg/DownloadIcon.svg" alt="" />
                                    </div>
                                </div>
                                {item?.engagementInvoiceCRMs.length>0 && 
                                 item?.engagementInvoiceCRMs.map((item,index)=>(

                                
                                <div className="box2col gap5">
                                    <p className="text-13">Invoice {index+1} : </p>
                                    <div className={item?.invoice ? "g-Tag" :"grey-Tag"} onClick={()=>{downloadImg(item?.invoice)}}>{item?.invoice? "Download" : "N/A"} 
                                            <img src="/assets/svg/DownloadIcon.svg" alt="" />
                                    </div>
                                </div>
                               ))}
                                </div>
                                </div>
                            }

                        { item?.sessionType==='Guest sessions' &&
                                <div className="accordianAnswer">
                                    <div className="floatingEdit" onClick={()=> {setEditEngagement(true);setSession(item)}}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="#737373"/>
                                            <path d="M8.49984 17.6901C7.88984 17.6901 7.32984 17.4701 6.91984 17.0701C6.42984 16.5801 6.21984 15.8701 6.32984 15.1201L6.75984 12.1101C6.83984 11.5301 7.21984 10.7801 7.62984 10.3701L15.5098 2.49006C17.4998 0.500059 19.5198 0.500059 21.5098 2.49006C22.5998 3.58006 23.0898 4.69006 22.9898 5.80006C22.8998 6.70006 22.4198 7.58006 21.5098 8.48006L13.6298 16.3601C13.2198 16.7701 12.4698 17.1501 11.8898 17.2301L8.87984 17.6601C8.74984 17.6901 8.61984 17.6901 8.49984 17.6901ZM16.5698 3.55006L8.68984 11.4301C8.49984 11.6201 8.27984 12.0601 8.23984 12.3201L7.80984 15.3301C7.76984 15.6201 7.82984 15.8601 7.97984 16.0101C8.12984 16.1601 8.36984 16.2201 8.65984 16.1801L11.6698 15.7501C11.9298 15.7101 12.3798 15.4901 12.5598 15.3001L20.4398 7.42006C21.0898 6.77006 21.4298 6.19006 21.4798 5.65006C21.5398 5.00006 21.1998 4.31006 20.4398 3.54006C18.8398 1.94006 17.7398 2.39006 16.5698 3.55006Z" fill="#737373"/>
                                            <path d="M19.8501 9.82978C19.7801 9.82978 19.7101 9.81978 19.6501 9.79978C17.0201 9.05978 14.9301 6.96978 14.1901 4.33978C14.0801 3.93978 14.3101 3.52978 14.7101 3.40978C15.1101 3.29978 15.5201 3.52978 15.6301 3.92978C16.2301 6.05978 17.9201 7.74978 20.0501 8.34978C20.4501 8.45978 20.6801 8.87978 20.5701 9.27978C20.4801 9.61978 20.1801 9.82978 19.8501 9.82978Z" fill="#737373"/>
                                        </svg>
                                    </div>
                                <div className="box2col mb-15">
                                </div>
                                <div className="box2col mb-15">
                                    <p className="text-13 width33">Programme: <b>{item?.programme ? item?.programme :"N/A"}</b></p>
                                    <p className="text-13 width33">Academic Year: <b>{item?.cohort ? item?.cohort:"N/A"}</b></p>
                                    <p className="text-13 width33">Date: <b>{item?.date ? formatDate(item?.date.split("/").join("-")):"N/A"}</b></p>
                                </div>
                                <div className="box2col mb-15">
                                    <p className="text-13 width33">Term: <b>{item?.courseTerm ? item?.courseTerm :"N/A"}</b></p>
                                    <p className="text-13 width33">Session Format : <b>{item?.sessionFormate ? item?.sessionFormate :"N/A"}</b></p>
                                    <p className="text-13 width33"> Theme/Topic: <b>{item?.teachingDate ? item?.teachingDate :"N/A"}</b></p>
                                </div>
                                <div className="box2col mb-15">
                                    <p className="text-13 width33">Takeaways : <b>{item?.studentNo ? item?.studentNo :"N/A"}</b></p>
                                    <p className="text-13 width33">Delivery Mode : <b>{item?.deliveryMode ? item?.deliveryMode :"N/A"}</b></p>
                                </div>
                                <p className="text-13 mb-15">Moderator: <b>{item?.overAllFeedback ? item?.overAllFeedback :"N/A"}</b></p>
                                <p className="text-13 mb-15">Class Registrations: <b>{item?.classCoordinate ? item?.classCoordinate :"N/A"}</b></p>
                                <div className="classDivider mb-15"></div>
                                <div className="box2col mb-10">
                                <div className="box2col gap5">
                                    <p className="text-13">Script: </p>
                                    <div className={item?.courseOutline ? "g-Tag" :"grey-Tag"} onClick={()=>{downloadImg(item?.courseOutline)}}>{item?.courseOutline? "Download" : "N/A"} 
                                            <img src="/assets/svg/DownloadIcon.svg" alt="" />
                                    </div>
                                </div>
                                <div className="box2col gap5">
                                    <p className="text-13">LOE: </p>
                                    <div className={item?.LOE ? "g-Tag" :"grey-Tag"} onClick={()=>{downloadImg(item?.LOE)}} >{item?.LOE? "Download" : "N/A"} 
                                            <img src="/assets/svg/DownloadIcon.svg" alt="" />
                                    </div>
                                </div>
                                {item?.engagementInvoiceCRMs.length>0 && 
                                 item?.engagementInvoiceCRMs.map((item,index)=>(

                                
                                <div className="box2col gap5">
                                    <p className="text-13">Invoice {index+1} : </p>
                                    <div className={item?.invoice ? "g-Tag" :"grey-Tag"} onClick={()=>{downloadImg(item?.invoice)}}>{item?.invoice? "Download" : "N/A"} 
                                            <img src="/assets/svg/DownloadIcon.svg" alt="" />
                                    </div>
                                </div>
                               ))}
                                
                                </div>
                                </div>
                            }

                          { item?.sessionType==='Mentorship' &&
                                <div className="accordianAnswer">
                                    <div className="floatingEdit" onClick={()=> {setEditEngagement(true); setSession(item)}}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="#737373"/>
                                            <path d="M8.49984 17.6901C7.88984 17.6901 7.32984 17.4701 6.91984 17.0701C6.42984 16.5801 6.21984 15.8701 6.32984 15.1201L6.75984 12.1101C6.83984 11.5301 7.21984 10.7801 7.62984 10.3701L15.5098 2.49006C17.4998 0.500059 19.5198 0.500059 21.5098 2.49006C22.5998 3.58006 23.0898 4.69006 22.9898 5.80006C22.8998 6.70006 22.4198 7.58006 21.5098 8.48006L13.6298 16.3601C13.2198 16.7701 12.4698 17.1501 11.8898 17.2301L8.87984 17.6601C8.74984 17.6901 8.61984 17.6901 8.49984 17.6901ZM16.5698 3.55006L8.68984 11.4301C8.49984 11.6201 8.27984 12.0601 8.23984 12.3201L7.80984 15.3301C7.76984 15.6201 7.82984 15.8601 7.97984 16.0101C8.12984 16.1601 8.36984 16.2201 8.65984 16.1801L11.6698 15.7501C11.9298 15.7101 12.3798 15.4901 12.5598 15.3001L20.4398 7.42006C21.0898 6.77006 21.4298 6.19006 21.4798 5.65006C21.5398 5.00006 21.1998 4.31006 20.4398 3.54006C18.8398 1.94006 17.7398 2.39006 16.5698 3.55006Z" fill="#737373"/>
                                            <path d="M19.8501 9.82978C19.7801 9.82978 19.7101 9.81978 19.6501 9.79978C17.0201 9.05978 14.9301 6.96978 14.1901 4.33978C14.0801 3.93978 14.3101 3.52978 14.7101 3.40978C15.1101 3.29978 15.5201 3.52978 15.6301 3.92978C16.2301 6.05978 17.9201 7.74978 20.0501 8.34978C20.4501 8.45978 20.6801 8.87978 20.5701 9.27978C20.4801 9.61978 20.1801 9.82978 19.8501 9.82978Z" fill="#737373"/>
                                        </svg>
                                    </div>
                                <div className="box2col mb-15">
                                </div>
                                <div className="box2col mb-15">
                                    <p className="text-13 width33">Programme: <b>{item?.programme ? item?.programme :"N/A"}</b></p>
                                    <p className="text-13 width33">Cohort: <b>{item?.cohort ? item?.cohort :"N/A"}</b></p>
                                </div>
                                <div className="box2col mb-15">
                                    <p className="text-13 width33">Focus Area/Expertise: <b>{item?.courseTerm ? item?.courseTerm :"N/A"}</b></p>
                                    <p className="text-13 width33">Date of Onboarding : <b>{item?.date ? formatDate(item?.date.split("/").join("-")) :"N/A"}</b></p>
                                </div>
                                <div className="box2col mb-15">
                                <p className="text-13 width33">Delivery Mode : <b>{item?.deliveryMode ? item?.deliveryMode :"N/A"}</b></p>
                                </div>
                                <div className="classDivider mb-15"></div>
                                <div className="box2col mb-10">
                                <div className="box2col gap5">
                                    <p className="text-13">LOE: </p>
                                    <div className={item?.LOE ? "g-Tag" :"grey-Tag"} onClick={()=>{downloadImg(item?.LOE)}} >{item?.LOE? "Download" : "N/A"} 
                                            <img src="/assets/svg/DownloadIcon.svg" alt="" />
                                    </div>
                                </div>
                                {item?.engagementInvoiceCRMs.length>0 && 
                                 item?.engagementInvoiceCRMs.map((item,index)=>(

                                
                                <div className="box2col gap5">
                                    <p className="text-13">Invoice {index+1} : </p>
                                    <div className={item?.invoice ? "g-Tag" :"grey-Tag"} onClick={()=>{downloadImg(item?.invoice)}}>{item?.invoice? "Download" : "N/A"} 
                                            <img src="/assets/svg/DownloadIcon.svg" alt="" />
                                    </div>
                                </div>
                               ))}
                                
                                </div>
                                </div>
                            }  

                          { item?.sessionType==='Others' &&
                                <div className="accordianAnswer">
                                    <div className="floatingEdit" onClick={()=> {setEditEngagement(true); setSession(item)}}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="#737373"/>
                                            <path d="M8.49984 17.6901C7.88984 17.6901 7.32984 17.4701 6.91984 17.0701C6.42984 16.5801 6.21984 15.8701 6.32984 15.1201L6.75984 12.1101C6.83984 11.5301 7.21984 10.7801 7.62984 10.3701L15.5098 2.49006C17.4998 0.500059 19.5198 0.500059 21.5098 2.49006C22.5998 3.58006 23.0898 4.69006 22.9898 5.80006C22.8998 6.70006 22.4198 7.58006 21.5098 8.48006L13.6298 16.3601C13.2198 16.7701 12.4698 17.1501 11.8898 17.2301L8.87984 17.6601C8.74984 17.6901 8.61984 17.6901 8.49984 17.6901ZM16.5698 3.55006L8.68984 11.4301C8.49984 11.6201 8.27984 12.0601 8.23984 12.3201L7.80984 15.3301C7.76984 15.6201 7.82984 15.8601 7.97984 16.0101C8.12984 16.1601 8.36984 16.2201 8.65984 16.1801L11.6698 15.7501C11.9298 15.7101 12.3798 15.4901 12.5598 15.3001L20.4398 7.42006C21.0898 6.77006 21.4298 6.19006 21.4798 5.65006C21.5398 5.00006 21.1998 4.31006 20.4398 3.54006C18.8398 1.94006 17.7398 2.39006 16.5698 3.55006Z" fill="#737373"/>
                                            <path d="M19.8501 9.82978C19.7801 9.82978 19.7101 9.81978 19.6501 9.79978C17.0201 9.05978 14.9301 6.96978 14.1901 4.33978C14.0801 3.93978 14.3101 3.52978 14.7101 3.40978C15.1101 3.29978 15.5201 3.52978 15.6301 3.92978C16.2301 6.05978 17.9201 7.74978 20.0501 8.34978C20.4501 8.45978 20.6801 8.87978 20.5701 9.27978C20.4801 9.61978 20.1801 9.82978 19.8501 9.82978Z" fill="#737373"/>
                                        </svg>
                                    </div>
                                <div className="box2col mb-15">
                                    {/* <p className="text-13 width33">Pricing: <b className='text-green'>₹ 50,00,000/-</b></p> */}
                                </div>
                                <div className="box2col mb-15">
                                    <p className="text-13 width33">Programme: <b>{item?.programme ? item?.programme :"N/A"}</b></p>
                                    <p className="text-13 width33">Cohort: <b>{item?.cohort ? item?.cohort :"N/A"}</b></p>
                                    <p className="text-13 width33">Engagement Type : <b>{item?.courseName ? item?.courseName :"N/A"}</b></p>
                                </div>
                                <div className="box2col mb-15">
                                <p className="text-13 width33">Delivery Mode : <b>{item?.deliveryMode ? item?.deliveryMode :"N/A"}</b></p>
                                    <p className="text-13 width33"> Date: <b>{item?.date ? formatDate(item?.date.split("/").join("-")):"N/A"}</b></p>
                                </div>
                                <div className="classDivider mb-15"></div>
                                <div className="box2col mb-10">
                                
                                </div>
                                </div>
                            }     
                    </div>
                    <div className="">
                        <p className="text-12">{formatDate(item?.endDate.split("/").join("-"))}<br /> <b>End Date</b></p>
                    </div>
                </div>
            </div>
            </>
            ))
        }
            
         </div>

         <div className={`masterTabDetail pd0 mt-10 ${tabActive === 4 && 'active'}`}>
            <div className="masterEngaement flexbox">
                <p className="text-18">Additional Notes</p>
                <Button className={'blackBtn'} iconName={'AddIcon'} text={'Add Notes'} onClick={()=> setShowAddNote(true)}/>
            </div>
                {mentorDetails[0]?.AdditionalNoteCRMs?.length > 0 && 
                 mentorDetails[0]?.AdditionalNoteCRMs?.map((item,index)=>(
              <div className="mainboxreviewtext">
               <div className='ratingboxshow'>
               <div className='datefield'><span>{moment(item?.date).format("DD/MM/YYYY")}</span></div>

               <p className='reviewtext'>
                    {item?.note}
                </p>
               </div>
              </div>
                 ))
           }
         </div>

         
         
    </div>
    </>
  )
}

export default MasterDetail;