import React, { useState } from 'react'
import InputField from '../../../../Components/InputField/InputField'
import SelectCustom from '../../../../Components/SelectCustom/SelectCustom'
import { AddCircleIcon } from '../../../../icon/icon'

const CreateMentorTab3 = ({ isActive, onChange, educationDetails, otherEducationCount, setOtherEducationCount, handleOtherEducation, setOtherEducation, otherEducation }) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    const otherEducationTemplate = {
        otherCollege: " ",
        degree: " ",
        specialization: " ",
        year: " ",
    }

    return (
        <div className='CreateMentorTab1'>
            <div className='columnTwoGap18'>
                {/* <SelectCustom isMulti={true} onChange={setSelectedOption}
                    value={selectedOption}
                    options={options}
                    labelName={'Select Account Type'}
                    placeholder={'Account Type'}
                /> */}
                <InputField labelName={'Undergrad College'} placeholder={'Enter Undergrad College'} notImp={true} name={"undergradCollege"} onChange={onChange} value={educationDetails?.undergradCollege} />
                <InputField labelName={'Undergrad Degree'} placeholder={'Enter Undergrad Degree'} notImp={true} name={"undergradDegree"} onChange={onChange} value={educationDetails?.undergradDegree} />
                <InputField labelName={'Undergrad Specialization'} placeholder={'Enter Undergrad Specialization'} notImp={true} name={"undergradSpecialization"} onChange={onChange} value={educationDetails?.undergradSpecialization} />
                <InputField labelName={'Undergrad Year'} placeholder={'Enter Undergrad Year'} notImp={true} name={"undergradYear"} onChange={onChange} value={educationDetails?.undergradYear}/>

                <InputField labelName={'Postgrad College'} placeholder={'Enter Postgrad College'} notImp={true} name={"postgradCollege"} onChange={onChange} value={educationDetails?.postgradCollege} />
                <InputField labelName={'Postgrad Degree'} placeholder={'Enter Postgrad Degree'} notImp={true} name={"postgradDegree"} onChange={onChange} value={educationDetails?.postgradDegree} />
                <InputField labelName={'Postgrad Specialization'} placeholder={'Enter Postgrad Specialization'} notImp={true} name={"postgradSpecialization"} onChange={onChange} value={educationDetails?.postgradSpecialization} />
                <InputField labelName={'Postgrad Year'} placeholder={'Enter Postgrad Year'} notImp={true} name={"postgradYear"} onChange={onChange} value={educationDetails?.postgradYear} />
                <InputField labelName={'PhD College'} placeholder={'Enter PhD College'} notImp={true} name={"phdCollege"} onChange={onChange} value={educationDetails?.phdCollege} />
                <InputField labelName={'PhD Area'} placeholder={'Enter PhD Area'} notImp={true} name={"phdArea"} onChange={onChange} value={educationDetails?.phdArea} />
                <InputField labelName={'PhD Year'} placeholder={'Enter PhD Year'} notImp={true} name={"phdYear"} onChange={onChange} value={educationDetails?.phdYear} />
            </div>
            {/* <div className='borderLine'></div> */}
            {/* <div className='columnTwoGap18'>
                <InputField notImp={true} type={'text'} labelName={'Other College'} placeholder={'Enter College'} />
                <InputField notImp={true} type={'text'} labelName={'Degree'} placeholder={'Enter Degree'} />
                <InputField notImp={true} type={'text'} labelName={'Specialization'} placeholder={'Enter Specialization'} />
                <InputField notImp={true} type={'text'} labelName={'Year'} placeholder={'Enter Year'} />
            </div> */}
            {otherEducationCount?.map((item, index) => (
                <>
                    <div className="box2col form2col mt-40">
                        {/* {console.log(otherEducation?.otherCollege,"OTHER COPLLEGE")} */}
                        <InputField labelName={`Other College -${index + 1}`} placeholder={'Enter College'} notImp={true} name={"otherCollege"} onChange={(e) => handleOtherEducation(e, index)} value={otherEducation?.length > 0 && otherEducation[index]?.otherCollege} />
                        <InputField labelName={'Degree'} placeholder={'Enter Degree'} notImp={true} name={"degree"} onChange={(e) => handleOtherEducation(e, index)} value={otherEducation?.length > 0 && otherEducation[index]?.degree} />
                    </div>
                    <div className="box2col form2col mt-20">
                        <InputField labelName={'Specialization'} placeholder={'Enter Specialization'} notImp={true} name={"specialization"} onChange={(e) => handleOtherEducation(e, index)} value={otherEducation?.length > 0 && otherEducation[index]?.specialization} />
                        <InputField labelName={'Year'} placeholder={'Enter Year'} notImp={true} name={"year"} onChange={(e) => handleOtherEducation(e, index)} value={otherEducation?.length > 0 && otherEducation[index]?.year} />
                    </div>
                </>
            ))}

            <div className='addMoreButtonBlock'>
                <button className='addmoreBtn' onClick={() => {setOtherEducationCount(prev => [...prev,0]); setOtherEducation(prev => [...prev,otherEducationTemplate])}}>Add other College <AddCircleIcon /></button>
            </div>
        </div>
    )
}

export default CreateMentorTab3