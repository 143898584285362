import React from 'react'
import './AdvanceFilterStyle.css';
import Button from '../Button/Button';
import { getAllMasters } from '../../reducer/masterSlice'
import { useDispatch } from 'react-redux';
import InputField from '../InputField/InputField'
import {
  cohortOptions, leadStageOptions, deliveryModeOptions, leadSourceOptions, industryTypeOptions
} from '../../Constants/FilterOptions'
import SingleSelectDropdown from '../SingleSelectDropdown/SingleSelectDropdown';

const AdvanceFilter = ({ showFilter, setshowFilter, filters, setFilters, setTotalMentors, }) => {

  const dispatch = useDispatch();

  const handleClick = () => {
    const obj = {
      focusAreas: filters.focusAreas,
      currentLocation: filters.currentLocation,
      engagement: filters.engagement,
      engagementType: filters.engagementType,
      programType: filters.programType,
      accHandler: filters.accHandler,
      engagementStatus: filters.engagementStatus,
      tiering: filters.tiering,
      dateRange: filters.dateRange,
      cohort: filters.cohort,
      courseType: filters.courseType,
      leadStage: filters.leadStage,
      leadSource: filters.leadSource,
      deliveryMode: filters.deliveryMode,
      industryType: filters.industryType,
      search: "",
      pageNo: 1,
    }
    setTotalMentors([])
    dispatch(getAllMasters(obj));
    setshowFilter(false)
  }

  const clearAllFilters = () => {
    setFilters((prev) => ({
      ...prev,
      cohort: '',
      courseType: '',
      leadStage: '',
      leadSource: '',
      deliverlyMode: '',
      industryType: '',
    }));
    setshowFilter(false)
  };

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className={`sideFilterBoxWrapper ${showFilter ? 'active' : ''}`}>
      <div className="sideFilterBox">
        <div className="popupHeader flexbox">
          <h4 className="popupHeading">Advance Filters</h4>
          <span onClick={() => setshowFilter(false)} className="close"><img src="/assets/svg/close.svg" alt="" /></span>
        </div>
        <div className="sideMenuBody">
          <div className='gapColumn16'>
            <SingleSelectDropdown
              customStyles
              label={'Cohort Session'}
              options={cohortOptions}
              selected={filters.cohort ? cohortOptions.find((opt) => opt.value === filters.cohort) : null}
              onChange={(option) => handleFilterChange('cohort', option?.value || '')}
              placeholder={'Select Cohort Session'}
              onClear={() => handleFilterChange('cohort', '')}
            />
            <SingleSelectDropdown
              customStyles
              label={'Lead Stage'}
              options={leadStageOptions}
              selected={filters.leadStage ? leadStageOptions.find((opt) => opt.value === filters.leadStage) : null}
              onChange={(option) => handleFilterChange('leadStage', option?.value || '')}
              placeholder={'Select Lead Stage'}
              onClear={() => handleFilterChange('leadStage', '')}
            />
            <SingleSelectDropdown
              customStyles
              label={'Delivery Mode'}
              options={deliveryModeOptions}
              selected={filters.deliveryMode ? deliveryModeOptions.find((opt) => opt.value === filters.deliveryMode) : null}
              onChange={(option) => handleFilterChange('deliveryMode', option?.value || '')}
              placeholder={'Select Delivery Mode'}
              onClear={() => handleFilterChange('deliveryMode', '')}
            />
            <SingleSelectDropdown
              customStyles
              label={'Lead Source'}
              options={leadSourceOptions}
              selected={filters.leadSource ? leadSourceOptions.find((opt) => opt.value === filters.leadSource) : null}
              onChange={(option) => handleFilterChange('leadSource', option?.value || '')}
              placeholder={'Select Lead Source'}
              onClear={() => handleFilterChange('leadSource', '')}
            />
            <SingleSelectDropdown
              customStyles
              label={'Industry Type'}
              options={industryTypeOptions}
              selected={filters.industryType ? industryTypeOptions.find((opt) => opt.value === filters.industryType) : null}
              onChange={(option) => handleFilterChange('industryType', option?.value || '')}
              placeholder={'Industry Type'}
              onClear={() => handleFilterChange('industryType', '')}
            />
          </div>
          {/* <InputField labelName={'Lead Stage'} className={'filterDrop'} placeholder={'Enter Current Location'} notImp={false} name={"currentLocation"} onChange={(e) => infoChange(e)} value={filter?.currentLocation}/> */}
        </div>
        <div className="sideMenuFooter flexbox">
          <Button className={'GreyFillButton'} text={'Reset'} onClick={() => clearAllFilters()} />
          <Button className={'BlueFillButton'} text={'Show Result'} onClick={() => handleClick()} />
        </div>
      </div>
    </div>
  )
}

export default AdvanceFilter