export const engagementTypeOptions = [
  { label: "Teaching", value: "Teaching" },
  { label: "Mentorship", value: "Mentorship" },
  { label: "Fireside Chat", value: "Fireside Chat" },
  { label: "Workshop", value: "Workshop" },
  { label: "Masterclass", value: "Masterclass" },
  { label: "Mentorship Roundtable", value: "Mentorship Roundtable" },
  { label: "One Day challenge", value: "One Day challenge" },
  { label: "OffCampus", value: "OffCampus" },
  { label: "B2B", value: "B2B" },
];

export const programTypeOptions = [
  { label: "UG Programme in Technology and Business Management", value: "UG Programme in Technology and Business Management" },
  { label: "UG Programme in Psychology and Marketing", value: "UG Programme in Psychology and Marketing" },
  { label: "UG Programme in Data Science & AI", value: "UG Programme in Data Science & AI" },
  { label: "PGP in Technology and Business Management YLC", value: "PGP in Technology and Business Management YLC" },
  { label: "PGP in Technology and Business Management", value: "PGP in Technology and Business Management" },
  { label: "PGP in Family Business Legacy Management", value: "PGP in Family Business Legacy Management" },
  { label: "PGP Rise: General Management", value: "PGP Rise: General Management" },
  { label: "PGP Rise: Owners and Promoters Management", value: "PGP Rise: Owners and Promoters Management" },
  { label: "PGP Rise: General Management (MENA)", value: "PGP Rise: General Management (MENA)" },
  { label: "Applied Finance", value: "Applied Finance" },
  { label: "Strategic Marketing & AI", value: "Strategic Marketing & AI" },
];


export const focusAreaOptions = [
  { label: "Finance", value: "Finance" },
  { label: "Sales & Marketing", value: "Sales & Marketing" },
  { label: "Human Resources Management (HRM)", value: "Human Resources Management (HRM)" },
  { label: "Business Analytics and Data Science", value: "Business Analytics and Data Science" },
  { label: "Economics", value: "Economics" },
  { label: "Leadership", value: "Leadership" },
  { label: "Entrepreneurship", value: "Entrepreneurship" },
  { label: "Strategy and Consulting", value: "Strategy and Consulting" },
  { label: "Supply Chain Management", value: "Supply Chain Management" },
  { label: "Operations Management", value: "Operations Management" },
  { label: "Investment Banking and Financial Markets", value: "Investment Banking and Financial Markets" },
  { label: "Digital Marketing", value: "Digital Marketing" },
  { label: "Project Management", value: "Project Management" },
  { label: "Risk Management", value: "Risk Management" },
  { label: "International Business", value: "International Business" },
];


export const accHandlerOptions = [
  { label: "Sudhanshu Garg", value: "Sudhanshu Garg" },
  { label: "Twesha Handa", value: "Twesha Handa" },
  { label: "Mohammad Iqbal Hassan", value: "Mohammad Iqbal Hassan" },
  { label: "Tasaduq Shafi Wani", value: "Tasaduq Shafi Wani" },
  { label: "Akshat Ghai", value: "Akshat Ghai" },
  { label: "Manisha Roy", value: "Manisha Roy" },
  { label: "Vishal Parashar", value: "Vishal Parashar" },
  { label: "Srishti Tyagi", value: "Srishti Tyagi" },
  { label: "Ashish Robinson", value: "Ashish Robinson" },
  { label: "Mukul Gupta", value: "Mukul Gupta" },
  { label: "Aniket Sharma", value: "Aniket Sharma" },
];

export const engagementStatusOptions = [
  { label: "Engaged", value: "Engaged" },
  { label: "Not Engaged", value: "Not Engaged" },
];

export const tieringOptions = [
  { label: "MU Masterminds", value: "MU Masterminds" },
  { label: "MU Masterverse", value: "MU Masterverse" },
  { label: "MU Adjunct Avengers", value: "MU Adjunct Avengers" },
];

// export const cohortOptions = [
//   "Cohort - 1", "Cohort - 2", "Cohort - 3", "Cohort - 4", "Cohort - 5",
//   "Cohort - 6", "Cohort - 7", "Cohort - 8", "Cohort - 9", "Cohort - 10",
//   "Cohort - 11", "Cohort - 12", "Cohort - 13"
// ];

// export const leadStageOptions = [
//   "Outreach Done", "Call Scheduled", "Meeting Done", "Response Awaited",
//   "No Response", "Engaged", "Closure Pending", "Engagement Scheduled",
//   "Dead Lead", "DN"
// ];

// export const deliveryModeOptions = ["Online", "Campus Visit", "Hybrid"];

// export const leadSourceOptions = ["Inbound", "Outbound", "Referral"];

export const cohortOptions = [
  { label: "Cohort - 1", value: "Cohort - 1" },
  { label: "Cohort - 2", value: "Cohort - 2" },
  { label: "Cohort - 3", value: "Cohort - 3" },
  { label: "Cohort - 4", value: "Cohort - 4" },
  { label: "Cohort - 5", value: "Cohort - 5" },
  { label: "Cohort - 6", value: "Cohort - 6" },
  { label: "Cohort - 7", value: "Cohort - 7" },
  { label: "Cohort - 8", value: "Cohort - 8" },
  { label: "Cohort - 9", value: "Cohort - 9" },
  { label: "Cohort - 10", value: "Cohort - 10" },
  { label: "Cohort - 11", value: "Cohort - 11" },
  { label: "Cohort - 12", value: "Cohort - 12" },
  { label: "Cohort - 13", value: "Cohort - 13" },
];

export const leadStageOptions = [
  { label: "Outreach Done", value: "Outreach Done" },
  { label: "Call Scheduled", value: "Call Scheduled" },
  { label: "Meeting Done", value: "Meeting Done" },
  { label: "Response Awaited", value: "Response Awaited" },
  { label: "No Response", value: "No Response" },
  { label: "Engaged", value: "Engaged" },
  { label: "Closure Pending", value: "Closure Pending" },
  { label: "Engagement Scheduled", value: "Engagement Scheduled" },
  { label: "Dead Lead", value: "Dead Lead" },
  { label: "DN", value: "DN" },
];

export const deliveryModeOptions = [
  { label: "Online", value: "Online" },
  { label: "Campus Visit", value: "Campus Visit" },
  { label: "Hybrid", value: "Hybrid" },
];

export const leadSourceOptions = [
  { label: "Inbound", value: "Inbound" },
  { label: "Outbound", value: "Outbound" },
  { label: "Referral", value: "Referral" },
];

export const industryTypeOptions = [
  { label: "Finance and Banking", value: "Finance and Banking" },
  { label: "Technology and IT Services", value: "Technology and IT Services" },
  { label: "Management Consulting", value: "Management Consulting" },
  { label: "Education and Research", value: "Education and Research" },
  { label: "Healthcare and Pharmaceuticals", value: "Healthcare and Pharmaceuticals" },
  { label: "Retail and E-Commerce", value: "Retail and E-Commerce" },
  { label: "Manufacturing and Industrial", value: "Manufacturing and Industrial" },
  { label: "Logistics and Supply Chain", value: "Logistics and Supply Chain" },
  { label: "Media and Entertainment", value: "Media and Entertainment" },
  { label: "Government and Public Sector", value: "Government and Public Sector" },
  { label: "Aerospace and Defense", value: "Aerospace and Defense" },
  { label: "Agriculture and Food Processing", value: "Agriculture and Food Processing" },
  { label: "Energy and Utilities", value: "Energy and Utilities" },
  { label: "Hospitality and Tourism", value: "Hospitality and Tourism" },
  { label: "Legal Services", value: "Legal Services" },
  { label: "Non-Profit and Social Impact", value: "Non-Profit and Social Impact" },
  { label: "Real Estate and Construction", value: "Real Estate and Construction" },
  { label: "Telecommunications", value: "Telecommunications" }
];


export const countryCodes = [
  {
    label: "+93 Afghanistan",
    value: "+93"
  },
  {
    label: "+355 Albania",
    value: "+355"
  },
  {
    label: "+213 Algeria",
    value: "+213"
  },
  {
    label: "+1-684 American Samoa",
    value: "+1-684"
  },
  {
    label: "+376 Andorra",
    value: "+376"
  },
  {
    label: "+244 Angola",
    value: "+244"
  },
  {
    label: "+1-264 Anguilla",
    value: "+1-264"
  },
  {
    label: "+672 Antarctica",
    value: "+672"
  },
  {
    label: "+1-268 Antigua and Barbuda",
    value: "+1-268"
  },
  {
    label: "+54 Argentina",
    value: "+54"
  },
  {
    label: "+374 Armenia",
    value: "+374"
  },
  {
    label: "+297 Aruba",
    value: "+297"
  },
  {
    label: "+61 Australia, Chad, Christmas Island, Cocos (Keeling) Islands",
    value: "+61"
  },
  {
    label: "+43 Austria",
    value: "+43"
  },
  {
    label: "+994 Azerbaijan",
    value: "+994"
  },
  {
    label: "+1-242 Bahamas",
    value: "+1-242"
  },
  {
    label: "+973 Bahrain",
    value: "+973"
  },
  {
    label: "+880 Bangladesh",
    value: "+880"
  },
  {
    label: "+1-246 Barbados",
    value: "+1-246"
  },
  {
    label: "+375 Belarus",
    value: "+375"
  },
  {
    label: "+32 Belgium",
    value: "+32"
  },
  {
    label: "+501 Belize",
    value: "+501"
  },
  {
    label: "+229 Benin",
    value: "+229"
  },
  {
    label: "+975 Bhutan",
    value: "+975"
  },
  {
    label: "+591 Bolivia",
    value: "+591"
  },
  {
    label: "+387 Bosnia and Herzegovina",
    value: "+387"
  },
  {
    label: "+267 Botswana",
    value: "+267"
  },
  {
    label: "+55 Brazil",
    value: "+55"
  },
  {
    label: "+246 British Indian Ocean Territory",
    value: "+246"
  },
  {
    label: "+1-284 British Virgin Islands",
    value: "+1-284"
  },
  {
    label: "+673 Brunei",
    value: "+673"
  },
  {
    label: "+359 Bulgaria",
    value: "+359"
  },
  {
    label: "+226 Burkina Faso",
    value: "+226"
  },
  {
    label: "+257 Burundi",
    value: "+257"
  },
  {
    label: "+238 Cape Verde",
    value: "+238"
  },
  {
    label: "+855 Cambodia",
    value: "+855"
  },
  {
    label: "+237 Cameroon",
    value: "+237"
  },
  {
    label: "+1 Canada, Dominica, Jamaica, Montserrat, Puerto Rico, Turks and Caicos Islands, United States",
    value: "+1"
  },
  {
    label: "+1-345 Cayman Islands",
    value: "+1-345"
  },
  {
    label: "+236 Central African Republic",
    value: "+236"
  },
  {
    label: "+57 Chile, Colombia",
    value: "+57"
  },
  {
    label: "+86 China",
    value: "+86"
  },
  {
    label: "+269 Comoros",
    value: "+269"
  },
  {
    label: "+242 Congo",
    value: "+242"
  },
  {
    label: "+243 Democratic Republic of the Congo",
    value: "+243"
  },
  {
    label: "+682 Cook Islands",
    value: "+682"
  },
  {
    label: "+506 Costa Rica",
    value: "+506"
  },
  {
    label: "+225 Côte d'Ivoire",
    value: "+225"
  },
  {
    label: "+385 Croatia",
    value: "+385"
  },
  {
    label: "+53 Cuba",
    value: "+53"
  },
  {
    label: "+599 Curaçao, Sint Maarten",
    value: "+599"
  },
  {
    label: "+357 Cyprus",
    value: "+357"
  },
  {
    label: "+420 Czech Republic",
    value: "+420"
  },
  {
    label: "+45 Denmark",
    value: "+45"
  },
  {
    label: "+253 Djibouti",
    value: "+253"
  },
  {
    label: "+1-809 Dominican Republic",
    value: "+1-809"
  },
  {
    label: "+670 East Timor, Timor-Leste",
    value: "+670"
  },
  {
    label: "+56 Ecuador",
    value: "+56"
  },
  {
    label: "+20 Egypt",
    value: "+20"
  },
  {
    label: "+503 El Salvador",
    value: "+503"
  },
  {
    label: "+240 Equatorial Guinea",
    value: "+240"
  },
  {
    label: "+291 Eritrea",
    value: "+291"
  },
  {
    label: "+372 Estonia",
    value: "+372"
  },
  {
    label: "+251 Ethiopia",
    value: "+251"
  },
  {
    label: "+500 Falkland Islands",
    value: "+500"
  },
  {
    label: "+298 Faroe Islands",
    value: "+298"
  },
  {
    label: "+679 Fiji",
    value: "+679"
  },
  {
    label: "+358 Finland",
    value: "+358"
  },
  {
    label: "+33 France",
    value: "+33"
  },
  {
    label: "+594 French Guiana",
    value: "+594"
  },
  {
    label: "+689 French Polynesia",
    value: "+689"
  },
  {
    label: "+241 Gabon",
    value: "+241"
  },
  {
    label: "+220 Gambia",
    value: "+220"
  },
  {
    label: "+995 Georgia",
    value: "+995"
  },
  {
    label: "+49 Germany",
    value: "+49"
  },
  {
    label: "+233 Ghana",
    value: "+233"
  },
  {
    label: "+350 Gibraltar",
    value: "+350"
  },
  {
    label: "+30 Greece",
    value: "+30"
  },
  {
    label: "+299 Greenland",
    value: "+299"
  },
  {
    label: "+147 Guam",
    value: "+147"
  },
  {
    label: "+502 Guatemala",
    value: "+502"
  },
  {
    label: "+44 Guernsey, Jersey, United Kingdom",
    value: "+44"
  },
  {
    label: "+224 Guinea",
    value: "+224"
  },
  {
    label: "+245 Guinea-Bissau",
    value: "+245"
  },
  {
    label: "+595 Guyana, Paraguay",
    value: "+595"
  },
  {
    label: "+509 Haiti",
    value: "+509"
  },
  {
    label: "+504 Honduras",
    value: "+504"
  },
  {
    label: "+852 Hong Kong",
    value: "+852"
  },
  {
    label: "+36 Hungary",
    value: "+36"
  },
  {
    label: "+354 Iceland",
    value: "+354"
  },
  {
    label: "+91 India",
    value: "+91"
  },
  {
    label: "+62 Indonesia",
    value: "+62"
  },
  {
    label: "+964 Iraq",
    value: "+964"
  },
  {
    label: "+353 Ireland",
    value: "+353"
  },
  {
    label: "+972 Israel",
    value: "+972"
  },
  {
    label: "+39 Italy, Vatican City",
    value: "+39"
  },
  {
    label: "+81 Japan",
    value: "+81"
  },
  {
    label: "+962 Jordan",
    value: "+962"
  },
  {
    label: "+7 Kazakhstan, Russia",
    value: "+7"
  },
  {
    label: "+254 Kenya",
    value: "+254"
  },
  {
    label: "+686 Kiribati",
    value: "+686"
  },
  {
    label: "+965 Kuwait",
    value: "+965"
  },
  {
    label: "+996 Kyrgyzstan",
    value: "+996"
  },
  {
    label: "+856 Laos",
    value: "+856"
  },
  {
    label: "+371 Latvia",
    value: "+371"
  },
  {
    label: "+961 Lebanon",
    value: "+961"
  },
  {
    label: "+266 Lesotho",
    value: "+266"
  },
  {
    label: "+231 Liberia",
    value: "+231"
  },
  {
    label: "+218 Libya",
    value: "+218"
  },
  {
    label: "+423 Liechtenstein",
    value: "+423"
  },
  {
    label: "+370 Lithuania",
    value: "+370"
  },
  {
    label: "+352 Luxembourg",
    value: "+352"
  },
  {
    label: "+853 Macao",
    value: "+853"
  },
  {
    label: "+389 Macedonia",
    value: "+389"
  },
  {
    label: "+261 Madagascar",
    value: "+261"
  },
  {
    label: "+265 Malawi",
    value: "+265"
  },
  {
    label: "+60 Malaysia",
    value: "+60"
  },
  {
    label: "+960 Maldives",
    value: "+960"
  },
  {
    label: "+223 Mali",
    value: "+223"
  },
  {
    label: "+356 Malta",
    value: "+356"
  },
  {
    label: "+692 Marshall Islands",
    value: "+692"
  },
  {
    label: "+596 Martinique",
    value: "+596"
  },
  {
    label: "+222 Mauritania",
    value: "+222"
  },
  {
    label: "+230 Mauritius",
    value: "+230"
  },
  {
    label: "+262 Mayotte",
    value: "+262"
  },
  {
    label: "+52 Mexico",
    value: "+52"
  },
  {
    label: "+691 Micronesia",
    value: "+691"
  },
  {
    label: "+373 Moldova",
    value: "+373"
  },
  {
    label: "+377 Monaco",
    value: "+377"
  },
  {
    label: "+1-670 Mongolia",
    value: "+1-670"
  },
  {
    label: "+382 Montenegro",
    value: "+382"
  },
  {
    label: "+212 Morocco",
    value: "+212"
  },
  {
    label: "+258 Mozambique",
    value: "+258"
  },
  {
    label: "+95 Myanmar",
    value: "+95"
  },
  {
    label: "+264 Namibia",
    value: "+264"
  },
  {
    label: "+674 Nauru",
    value: "+674"
  },
  {
    label: "+977 Nepal",
    value: "+977"
  },
  {
    label: "+31 Netherlands",
    value: "+31"
  },
  {
    label: "+64 New Zealand",
    value: "+64"
  },
  {
    label: "+505 Nicaragua",
    value: "+505"
  },
  {
    label: "+227 Niger",
    value: "+227"
  },
  {
    label: "+234 Nigeria",
    value: "+234"
  },
  {
    label: "+683 Niue",
    value: "+683"
  },
  {
    label: "+850 North Korea",
    value: "+850"
  },
  {
    label: "+47 Norway, Svalbard and Jan Mayen",
    value: "+47"
  },
  {
    label: "+968 Oman",
    value: "+968"
  },
  {
    label: "+92 Pakistan",
    value: "+92"
  },
  {
    label: "+680 Palau",
    value: "+680"
  },
  {
    label: "+970 Palestine",
    value: "+970"
  },
  {
    label: "+507 Panama",
    value: "+507"
  },
  {
    label: "+675 Papua New Guinea",
    value: "+675"
  },
  {
    label: "+51 Peru",
    value: "+51"
  },
  {
    label: "+63 Philippines",
    value: "+63"
  },
  {
    label: "+48 Poland",
    value: "+48"
  },
  {
    label: "+351 Portugal",
    value: "+351"
  },
  {
    label: "+974 Qatar",
    value: "+974"
  },
  {
    label: "+40 Romania",
    value: "+40"
  },
  {
    label: "+250 Rwanda",
    value: "+250"
  },
  {
    label: "+685 Samoa",
    value: "+685"
  },
  {
    label: "+378 San Marino",
    value: "+378"
  },
  {
    label: "+239 Sao Tome and Principe",
    value: "+239"
  },
  {
    label: "+966 Saudi Arabia",
    value: "+966"
  },
  {
    label: "+221 Senegal",
    value: "+221"
  },
  {
    label: "+381 Serbia",
    value: "+381"
  },
  {
    label: "+248 Seychelles",
    value: "+248"
  },
  {
    label: "+232 Sierra Leone",
    value: "+232"
  },
  {
    label: "+65 Singapore",
    value: "+65"
  },
  {
    label: "+421 Slovakia",
    value: "+421"
  },
  {
    label: "+386 Slovenia",
    value: "+386"
  },
  {
    label: "+677 Solomon Islands",
    value: "+677"
  },
  {
    label: "+27 South Africa",
    value: "+27"
  },
  {
    label: "+82 South Korea",
    value: "+82"
  },
  {
    label: "+211 South Sudan",
    value: "+211"
  },
  {
    label: "+34 Spain",
    value: "+34"
  },
  {
    label: "+94 Sri Lanka",
    value: "+94"
  },
  {
    label: "+249 Sudan",
    value: "+249"
  },
  {
    label: "+597 Surilabel",
    value: "+597"
  },
  {
    label: "+46 Sweden",
    value: "+46"
  },
  {
    label: "+41 Switzerland, Switzerland",
    value: "+41"
  },
  {
    label: "+963 Syria",
    value: "+963"
  },
  {
    label: "+886 Taiwan",
    value: "+886"
  },
  {
    label: "+992 Tajikistan",
    value: "+992"
  },
  {
    label: "+255 Tanzania, Zimbabwe",
    value: "+255"
  },
  {
    label: "+66 Thailand",
    value: "+66"
  },
  {
    label: "+228 Togo",
    value: "+228"
  },
  {
    label: "+690 Tokelau",
    value: "+690"
  },
  {
    label: "+676 Tonga",
    value: "+676"
  },
  {
    label: "+1-868 Trinidad and Tobago",
    value: "+1-868"
  },
  {
    label: "+216 Tunisia",
    value: "+216"
  },
  {
    label: "+90 Turkey",
    value: "+90"
  },
  {
    label: "+993 Turkmenistan",
    value: "+993"
  },
  {
    label: "+688 Tuvalu",
    value: "+688"
  },
  {
    label: "+256 Uganda",
    value: "+256"
  },
  {
    label: "+380 Ukraine",
    value: "+380"
  },
  {
    label: "+971 United Arab Emirates",
    value: "+971"
  },
  {
    label: "+598 Uruguay",
    value: "+598"
  },
  {
    label: "+998 Uzbekistan",
    value: "+998"
  },
  {
    label: "+678 Vanuatu",
    value: "+678"
  },
  {
    label: "+58 Venezuela",
    value: "+58"
  },
  {
    label: "+84 Vietnam",
    value: "+84"
  },
  {
    label: "+1-340 Virgin Islands",
    value: "+1-340"
  },
  {
    label: "+681 Wallis and Futuna",
    value: "+681"
  },
  {
    label: "+967 Yemen",
    value: "+967"
  },
  {
    label: "+260 Zambia",
    value: "+260"
  }
]