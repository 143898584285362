import React, { useState } from 'react'
import InputField from '../../../../Components/InputField/InputField'
import { AddCircleIcon } from '../../../../icon/icon'

const CreateMentorTab4 = ({ isActive, currentWorkCount, setCurrentWorkCount, setCurrentWork, onChange, handleCurrentWorkChange, peviousWorkCount, handlePreviousWorkChange, setPrevioustWorkCount, setPreviousWork, previousWork, currentWork }) => {

    const currentWorkTemplate = {
        currentDesignation: "",
        currentCompany: "",
        currentLocation: ""
    };

    const previousWorkTemplate = {
        previousDesignation: "",
        previousCompany: "",
        previousLocation: ""
    };
    return (
        <div className='CreateMentorTab1'>
            {currentWorkCount?.map((item, index) => (
                <>
                    <div className="box2col form2col">
                        <InputField labelName={`Current Designation-${index + 1}`} placeholder={'Enter Current Designation'} notImp={true} name={"currentDesignation"} onChange={(e) => handleCurrentWorkChange(e, index)} value={currentWork?.length > 0 && currentWork[index]?.currentDesignation} />
                        <InputField labelName={`Current Company-${index + 1}`} placeholder={'Enter Current Company'} notImp={true} name={"currentCompany"} onChange={(e) => handleCurrentWorkChange(e, index)} value={currentWork?.length > 0 && currentWork[index]?.currentCompany} />
                    </div>
                    <div className="width50 mt-20">
                        <InputField labelName={`Current Location -${index + 1}`} placeholder={'Enter Current Location'} notImp={true} name={"currentLocation"} onChange={(e) => handleCurrentWorkChange(e, index)} value={currentWork?.length > 0 && currentWork[index]?.currentLocation} />
                    </div>
                </>
            ))}

            <div className='addMoreButtonBlock'>
                <button className='addmoreBtn' onClick={() => { setCurrentWorkCount(prev => [...prev, 0]); setCurrentWork(prev => [...prev, currentWorkTemplate]) }}>Add Current Work <AddCircleIcon /></button>
            </div>

            <div className='borderLine'></div>

            {peviousWorkCount?.map((item, index) => (
                <>
                    <div className="box2col form2col mt-40">
                        <InputField labelName={`Previous Designation -${index + 1}`} placeholder={'Enter Previous Designation - 1'} notImp={true} name={"previousDesignation"} onChange={(e) => handlePreviousWorkChange(e, index)} value={previousWork?.length > 0 && previousWork[index]?.previousDesignation} />
                        <InputField labelName={`Previous Company -${index + 1}`} placeholder={'Enter Previous Company - 1'} notImp={true} name={"previousCompany"} onChange={(e) => handlePreviousWorkChange(e, index)} value={previousWork?.length > 0 && previousWork[index]?.previousCompany} />
                    </div>
                    <div className="width50 mt-20">
                        <InputField labelName={`Previous Location -${index + 1}`} placeholder={'Enter Previous Location -1'} notImp={true} name={"previousLocation"} onChange={(e) => handlePreviousWorkChange(e, index)} value={previousWork?.length > 0 && previousWork[index]?.previousLocation} />
                        {/* <InputField labelName={'Previous Company - 2'} placeholder={'Enter Previous Company - 2'} notImp={true}  name={"previousCompany2"} onChange={onChange}/> */}
                    </div>
                </>
            ))}

            <div className='addMoreButtonBlock'>
                <button className='addmoreBtn' onClick={() => {setPrevioustWorkCount(prev => [...prev,0]); setPreviousWork(prev => [...prev,previousWorkTemplate])}}>Add Other Previous Work <AddCircleIcon /></button>
            </div>
        </div >
    )
}

export default CreateMentorTab4