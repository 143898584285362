import React from 'react'

const CrossIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="18" rx="9" fill="#8A8B9B" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.75711 4.75754C5.01746 4.49719 5.43957 4.49719 5.69992 4.75754L13.2424 12.3C13.5027 12.5604 13.5027 12.9825 13.2424 13.2428C12.982 13.5032 12.5599 13.5032 12.2996 13.2428L4.75711 5.70035C4.49676 5.44 4.49676 5.01789 4.75711 4.75754Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2429 4.75754C13.5032 5.01789 13.5032 5.44 13.2429 5.70035L5.70042 13.2428C5.44007 13.5032 5.01796 13.5032 4.75761 13.2428C4.49726 12.9825 4.49726 12.5604 4.75761 12.3L12.3001 4.75754C12.5604 4.49719 12.9825 4.49719 13.2429 4.75754Z" fill="white" />
        </svg>

    )
}

const CommentIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M22 7.43018V13.4302C22 14.9302 21.5 16.1802 20.62 17.0602C19.75 17.9302 18.5 18.4302 17 18.4302V20.5602C17 21.3602 16.11 21.8402 15.45 21.4002L11 18.4302H8.88C8.96 18.1302 9 17.8202 9 17.5002C9 16.4802 8.61 15.5402 7.97 14.8302C7.25 14.0102 6.18 13.5002 5 13.5002C3.88 13.5002 2.86 13.9602 2.13 14.7102C2.04 14.3102 2 13.8802 2 13.4302V7.43018C2 4.43018 4 2.43018 7 2.43018H17C20 2.43018 22 4.43018 22 7.43018Z" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 17.5C9 18.25 8.79001 18.96 8.42001 19.56C8.21001 19.92 7.94 20.24 7.63 20.5C6.93 21.13 6.01 21.5 5 21.5C3.54 21.5 2.26999 20.72 1.57999 19.56C1.20999 18.96 1 18.25 1 17.5C1 16.24 1.58 15.11 2.5 14.38C3.19 13.83 4.06 13.5 5 13.5C7.21 13.5 9 15.29 9 17.5Z" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.43994 17.5L4.42993 18.49L6.55994 16.52" stroke="#5243AA" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.5 10.5H15.5" stroke="#5243AA" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
const AddCircleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8.00016 14.6668C11.6668 14.6668 14.6668 11.6668 14.6668 8.00016C14.6668 4.3335 11.6668 1.3335 8.00016 1.3335C4.3335 1.3335 1.3335 4.3335 1.3335 8.00016C1.3335 11.6668 4.3335 14.6668 8.00016 14.6668Z" stroke="#0052CC" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.3335 8H10.6668" stroke="#0052CC" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 10.6668V5.3335" stroke="#0052CC" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
const EyeIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M10.3866 7.99995C10.3866 9.31995 9.31995 10.3866 7.99995 10.3866C6.67995 10.3866 5.61328 9.31995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C9.31995 5.61328 10.3866 6.67995 10.3866 7.99995Z" stroke={color ? color : "#848484"} stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z" stroke={color ? color : "#848484"} stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
const DownloadFile = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M10.96 5.93311C13.36 6.13977 14.34 7.37311 14.34 10.0731V10.1598C14.34 13.1398 13.1467 14.3331 10.1667 14.3331H5.82665C2.84665 14.3331 1.65332 13.1398 1.65332 10.1598V10.0731C1.65332 7.39311 2.61999 6.15977 4.97999 5.93977" stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 1.3335V9.92016" stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.2333 8.43311L7.99994 10.6664L5.7666 8.43311" stroke="#848484" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export { CrossIcon, CommentIcon, AddCircleIcon, EyeIcon, DownloadFile }