import React, { useState } from 'react'
import InputField from '../../../../Components/InputField/InputField'
import SelectCustom from '../../../../Components/SelectCustom/SelectCustom'
import CustomUploadBox from '../../../../Components/CustomUploadBox/CustomUploadBox'
import * as filestack from "filestack-js";
import CustomDropdown from '../../../../Components/CustomDropdown/CustomDropdown';

const CreateMentorTab2 = ({ isActive, bankDetails, onChange, setPhotos, photos, infoBank }) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    const [dropdownToggler, setDropdownToggler] = useState(false);
    const DropdownValue = ['Personal account', 'Representative account', 'Company account'];

    const [load, setLoad] = useState(false);

    const handleUploadPic = (event, type) => {
        const client = filestack.init("AASYllbvHQ3u0A1GxbOvZz");

        client.picker({
            accept: ['image/*'],
            onUploadDone: (result) => {
                if (result?.filesFailed?.length > 0) {
                    setLoad(false);
                    alert("image file not supported");
                }
                if (result?.filesUploaded?.length > 0) {
                    console.log("data", result?.filesUploaded);
                    setPhotos(prev => ({
                        ...prev,
                        [type]: {
                            url: `https://filestack.mastersunion.org/${result?.filesUploaded[0]?.key}`,
                            name: result?.filesUploaded[0]?.filename
                        }
                    }))
                    setLoad(false);
                }
            },
        }).open();
    };

    const clearPic = (e, type) => {
        setPhotos(prev => ({
            ...prev,
            [type]: { url: "", name: "" }
        }))
    };

    return (
        <div className='CreateMentorTab1'>
            <div className='columnTwoGap18'>
                {/* <SelectCustom isMulti={true} onChange={setSelectedOption}
                    value={selectedOption}
                    options={options}
                    labelName={'Select Account Type'}
                    placeholder={'Account Type'}
                /> */}
                <InputField labelName={'Bank Name'} placeholder={'Enter Bank Name'} notImp={true} name={"bankName"} value={bankDetails?.bankName} onChange={(e) => infoBank(e)} />
                <InputField labelName={'Account Name'} placeholder={'Enter Account Name'} notImp={true} name={"accountName"} onChange={(e) => infoBank(e)} value={bankDetails?.accountName} />
                <InputField labelName={'Account Number'} placeholder={'Enter Account Number'} notImp={true} name={"accountNumber"} onChange={(e) => infoBank(e)} value={bankDetails?.accountNumber} />
                {/* <SelectCustom isMulti={true} onChange={setSelectedOption}
                    value={selectedOption}
                    options={options}
                    labelName={'Account Type'}
                    placeholder={'Enter Account Number'}
                /> */}
                <CustomDropdown label={'Account Type'} className={'filterDrop'} dropName={'Enter Account Number'} options={DropdownValue} data={bankDetails?.accountType} isActive={dropdownToggler} setIsActive={setDropdownToggler} onChange={(e) => infoBank(e)} name={"accountType"} />


                <InputField labelName={'IFSC / Swift Code'} placeholder={'Enter IFSC / Swift Code'} notImp={true} name={"ifscCode"} onChange={(e) => infoBank(e)} value={bankDetails?.ifscCode} />
                <InputField labelName={'Routing Number'} placeholder={'Enter Routing Number'} notImp={true} name={"routingNumber"} onChange={(e) => infoBank(e)} value={bankDetails?.routingNumber} />
                <InputField labelName={'GST Number'} placeholder={'Enter your GST Number'} notImp={true} name={"gstNumber"} onChange={(e) => infoBank(e)} value={bankDetails?.gstNumber} />

                {/* <CustomUploadBox
                    file={'kk'}
                    csvUpload={true}
                    className={'uploadFileBlock'}
                    labelName={'Cancelled Cheque'}
                    notImp={true}
                /> */}

                <InputField labelName={'PAN Number'} placeholder={'Enter your PAN Number'} notImp={true} name={"panNumber"} onChange={(e) => infoBank(e)} value={bankDetails?.panNumber} />
                <InputField labelName={'Aadhar Number'} placeholder={'Enter your Aadhar Number'} notImp={true} name={"aadharNumber"} onChange={(e) => infoBank(e)} value={bankDetails?.aadharNumber} />
                <div className="boxLabel">
                    <span className="label">Cancelled Cheque</span>
                    <CustomUploadBox
                        value={photos["chequeUrl"].name}
                        onChange={(e) => handleUploadPic(e, "chequeUrl")}
                        onClick={(e) => clearPic(e, "chequeUrl")}
                    />
                </div>
                {/* <CustomUploadBox
                    file={'kk'}
                    csvUpload={true}
                    className={'uploadFileBlock'}
                    labelName={'PAN Card Photo'}
                    notImp={true}
                /> */}

                <div className="boxLabel">
                    <span className="label">PAN Card Photo</span>
                    <CustomUploadBox onChange={(e) => handleUploadPic(e, "panCardUrl")}
                        value={photos["panCardUrl"].name}
                        onClick={(e) => clearPic(e, "panCardUrl")}
                    />
                </div>
                {/* <CustomUploadBox
                    file={'kk'}
                    csvUpload={true}
                    className={'uploadFileBlock'}
                    labelName={'Aadhar Card Photo'}
                    notImp={true}
                />
                <CustomUploadBox
                    file={'kk'}
                    csvUpload={true}
                    className={'uploadFileBlock'}
                    labelName={'Other Photo'}
                    notImp={true}
                /> */}

                <div className="boxLabel">
                    <span className="label">Aadhar Card Photo</span>
                    <CustomUploadBox
                        onChange={(e) => handleUploadPic(e, "aadharCardUrl")}
                        value={photos["aadharCardUrl"].name}
                        onClick={(e) => clearPic(e, "aadharCardUrl")}
                    />
                </div>
                <div className="boxLabel">
                    <span className="label">Other Photo</span>
                    <CustomUploadBox onChange={(e) => handleUploadPic(e, "otherPhotoUrl")}
                        value={photos["otherPhotoUrl"].name}
                        onClick={(e) => clearPic(e, "otherPhotoUrl")}
                    />
                </div>
            </div>
        </div>
    )
}

export default CreateMentorTab2