import React from "react";
import "./PageLoaderStyle.css";

const PageLoader = () => {
  return (
    <div className="preloader-wrapper">
      <div className="cssloader">
        <img src="https://cdn.mastersunion.org/assets/img/master2.svg" alt="" />
      </div>
    </div>
  );
};
export default PageLoader;
