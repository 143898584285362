import React, { useState } from 'react';
import style from '../MultiSelectDropdown/MultiSelectDropdown.module.css';
import { CrossIcon } from '../../icon/icon';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const CalenderCustom = ({
    className,
    value,
    onChange
}) => {

    // const [dateRange, setDateRange] = useState(null);

    return (
        <div className={`${style.multiSelectBlock} multiSelectBlockCustom ${className}`}>
            <div className={`${style.tagFilterWrap}`}>
                {/* <CrossIcon /> */}
                <p className={`text14n mb8`}>Date Range</p>
            </div>

            <div className={`${style.calenderBlock} calenderDateRange`}>
                {/* <img src="/assets/svg/calender.svg" alt="" /> */}
                <DateRangePicker
                    placeholder="Select date range"
                    value={value}
                    onChange={onChange}
                    format="yyyy-MM-dd"
                    showOneCalendar
                />
            </div>
        </div>
    );
};

export default CalenderCustom;
