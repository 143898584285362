import React, { useState, useEffect } from 'react'
import InputField from '../../../../Components/InputField/InputField'
import SelectCustom from '../../../../Components/SelectCustom/SelectCustom'
import * as filestack from "filestack-js";
import CustomRadioButton from '../../../../Components/CustomRadioButton/CustomRadioButton';
import { TagsInput } from 'react-tag-input-component';
import CustomDropdown from '../../../../Components/CustomDropdown/CustomDropdown';
import CustomDropdown2 from '../../../../Components/CustomDropdown2/CustomDropdown2';
import Searchdropdown from '../../../../Components/Searchdropdown/Searchdropdown';
import { countryCodes } from '../../../../Constants/FilterOptions';

const CreateMentorTab1 = ({ isActive, personalInfo, setPersonalInfo, onChange, setPhotos, photos, aliasState, setAliasState, isError }) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    const DropdownValue = ['Finance', 'Sales and Marketing',
        'Human Resources Management (HRM)',
        'Operations Management',
        'Entrepreneurship',
        'Business Analytics and Data Science',
        'Strategy and Consulting',
        'Information Technology',
        'Banking and Investments ',
        'Supply Chain',
        'Product Management',
        'Leadership ',
        'PE/VC',
        'Communications'
    ];
    const DropdownLead = ['Inbound', 'Outbound', 'referral']
    const [dropdownToggler, setDropdownToggler] = useState(false);
    const [dropdownToggler3, setDropdownToggler3] = useState(false);
    const [dropdownToggler4, setDropdownToggler4] = useState(false);
    const [dropdownToggler5, setDropdownToggler5] = useState(false);
    const [dropdownToggler6, setDropdownToggler6] = useState(false);
    const [dropdownToggler7, setDropdownToggler7] = useState(false);
    const [dropdownToggler8, setDropdownToggler8] = useState(false);
    const [dropdownToggler9, setDropdownToggler9] = useState(false);

    const [load, setLoad] = useState(false);

    const handleUploadProfile = () => {
        const client = filestack.init("AASYllbvHQ3u0A1GxbOvZz");

        client.picker({
            accept: ['image/*'],
            onUploadDone: (result) => {
                if (result?.filesFailed?.length > 0) {
                    setLoad(false);
                    alert("image file not supported");
                }
                if (result?.filesUploaded?.length > 0) {
                    console.log("data", result?.filesUploaded);
                    setPhotos(prev => ({
                        ...prev,
                        ["profileUrl"]: {
                            url: `https://filestack.mastersunion.org/${result?.filesUploaded[0]?.key}`,
                            name: result?.filesUploaded[0]?.filename
                        }
                    }))
                    setLoad(false);
                }
            },
        }).open();
    };

    const clearProfile = () => {
        // console.log("urlremove");
        setPhotos(prev => ({
            ...prev,
            ["profileUrl"]: { url: "", name: "" }
        }))
    };
    // console.log("resulttt", photos.profileUrl);


    //////////////////Country/state/City(residance)/////////////////////////

    var headers = new Headers();
    headers.append("X-CSCAPI-KEY", "UVAwbkxwMDZJWVdkY25UNnhZS2ozTHdVM3FVUm5kODlsU0NDUG5mNA==");

    var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    };

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [selectedCountry2, setSelectedCountry2] = useState(null);
    const [selectedState2, setSelectedState2] = useState(null);
    const [selectedCity2, setSelectedCity2] = useState(null);
    const [countries2, setCountries2] = useState([]);
    const [states2, setStates2] = useState([]);
    const [cities2, setCities2] = useState([]);
    useEffect(() => {
        // Fetch countries
        fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                const countryOptions = data.map((country) => {
                    return {
                        label: country.name,
                        value: country.iso2
                    }
                });
                setCountries(countryOptions);
                setCountries2(countryOptions);
            })
            .catch((error) => {
                console.error('Error fetching countries:', error);
            });
    }, []);


    useEffect(() => {
        // Fetch states based on selected country
        if (selectedCountry) {
            fetch(`https://api.countrystatecity.in/v1/countries/${selectedCountry?.value}/states`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    const stateOptions = data.map((state) => ({
                        label: state.name,
                        value: state.iso2,
                    }));
                    setStates(stateOptions);
                })
                .catch((error) => {
                    console.error('Error fetching states:', error);
                });
        }
        if (selectedCountry2) {
            fetch(`https://api.countrystatecity.in/v1/countries/${selectedCountry2?.value}/states`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    const stateOptions = data.map((state) => ({
                        label: state.name,
                        value: state.iso2,
                    }));
                    setStates2(stateOptions);
                })
                .catch((error) => {
                    console.error('Error fetching states:', error);
                });
        }
    }, [selectedCountry, selectedCountry2]);

    useEffect(() => {
        // Fetch cities based on selected state
        if (selectedState) {
            fetch(`https://api.countrystatecity.in/v1/countries/${selectedCountry?.value}/states/${selectedState?.value}/cities`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    const cityOptions = data.map((city) => ({
                        label: city.name,
                        value: city.id,
                    }));
                    setCities(cityOptions);
                })
                .catch((error) => {
                    console.error('Error fetching cities:', error);
                });
        }

        if (selectedState2) {
            fetch(`https://api.countrystatecity.in/v1/countries/${selectedCountry2?.value}/states/${selectedState2?.value}/cities`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data);
                    const cityOptions = data.map((city) => ({
                        label: city.name,
                        value: city.id,
                    }));
                    setCities2(cityOptions);
                })
                .catch((error) => {
                    console.error('Error fetching cities:', error);
                });
        }
    }, [selectedState, selectedState2]);

    const handleCountryChange = (object) => {
        const { label } = object;
        setPersonalInfo(prev => ({
            ...prev,
            country: label
        }))
        setPersonalInfo(prev => ({
            ...prev,
            residanceState: ""
        }))
        setPersonalInfo(prev => ({
            ...prev,
            residanceCity: ""
        }))

        setSelectedCountry(object);
        setSelectedState(null);
        setSelectedCity(null);
    };
    const handleCountryCodes = (object) => {
        // console.log(object,"object");
        
        const { label } = object;
        setPersonalInfo(prev => ({
            ...prev,
            countryCode: label
        }))
    };
    // console.log(selectedCountry,"POPo")

    const handleStateChange = (object) => {
        const { label } = object;
        setPersonalInfo(prev => ({
            ...prev,
            residanceState: label
        }))
        setPersonalInfo(prev => ({
            ...prev,
            residanceCity: ""
        }))
        setSelectedState(object);
        setSelectedCity(null);
    };

    const handleCityChange = (selectedOption) => {
        const { label } = selectedOption;
        setPersonalInfo(prev => ({
            ...prev,
            residanceCity: label
        }))
        setSelectedCity(selectedOption);
    };


    //////////////////Country/state/City(work)///////////////////////// 

    const handleCountryChange2 = (object) => {
        const { label } = object;
        setPersonalInfo(prev => ({
            ...prev,
            workCountry: label
        }))
        setPersonalInfo(prev => ({
            ...prev,
            workState: ""
        }))
        setPersonalInfo(prev => ({
            ...prev,
            workCity: ""
        }))
        setSelectedCountry2(object);
        setSelectedState2(null);
        setSelectedCity2(null);
    };
    // console.log(selectedCountry,"POPo")

    const handleStateChange2 = (object) => {
        const { label } = object;
        setPersonalInfo(prev => ({
            ...prev,
            workState: label
        }))
        setPersonalInfo(prev => ({
            ...prev,
            workCity: ""
        }))
        setSelectedState2(object);
        setSelectedCity2(null);
    };

    const handleCityChange2 = (selectedOption) => {
        const { label } = selectedOption;
        setPersonalInfo(prev => ({
            ...prev,
            workCity: label
        }))
        setSelectedCity2(selectedOption);
    };


    return (
        <div className='createMentorTab1'>
            <div className='imageUploadProfile mb40'>
                {!photos.profileUrl.url && <label htmlFor='uploadBox' className='imageDummyBox'>
                    <img src="/assets/svg/imageEdit.svg" alt="" className='imageEditIcon' onClick={() => handleUploadProfile()} />
                    <img src="/assets/svg/profileIcon.svg" alt="" className='profileIcon' />
                    <input type="file" id='file' />
                </label>}
                {photos.profileUrl.url &&  <label htmlFor='uploadBox' className='imageDummyBox'>
                    <img src={!photos?.profileUrl?.url ? "/assets/img/userBG.png" : photos?.profileUrl?.url} alt="" className='profileIcon' />
                    <span className='imageEditIcon2'><img src="/assets/svg/trash.svg" alt="" onClick={clearProfile}  /></span>
                </label>
                }
                <span className='imageName'>Allowed file types: png, jpg, jpeg.</span>
            </div>

            <div className='columnThreeGap18'>
               <div className="rowGap16">
               <CustomRadioButton name={'title'} className={' radioWrap'} text='Mr.' value={'Mr.'} onChange={onChange} checked={personalInfo?.title === 'Mr.' ? true : ""} />
                <CustomRadioButton name={'title'} className={' radioWrap'} text='Ms.' value={'Ms.'} onChange={onChange} checked={personalInfo?.title === 'Ms.' ? true : ""} />
                <CustomRadioButton name={'title'} className={' radioWrap'} text='Mrs.' value={'Mrs.'} onChange={onChange} checked={personalInfo?.title === 'Mrs.' ? true : ""} />
                <CustomRadioButton name={'title'} className={' radioWrap'} text='Prof.' value={'Prof.'} onChange={onChange} checked={personalInfo?.title === 'Prof.' ? true : ""} />
                <CustomRadioButton name={'title'} className={' radioWrap'} text='Dr.' value={'Dr.'} onChange={onChange} checked={personalInfo?.title === 'Dr.' ? true : ""} />
               </div>
                <InputField notImp={false} labelName={'First Name'} placeholder={'Enter First Name'} errorMessage={isError["firstName"]} name={"firstName"} onChange={onChange} value={personalInfo?.firstName} />
                <InputField notImp={true} labelName={'Last Name'} placeholder={'Enter First Name'} errorMessage={isError["lastName"]} name={"lastName"} onChange={onChange} value={personalInfo?.lastName} />
                <div className="box2col  TagInputbar">
                    <div className='labletabinput'>Alias Name <span class="impRed"> *</span></div>
                    <TagsInput value={personalInfo?.alias} onChange={setAliasState} name="alias" placeHolder="Enter your Alias Name" />
                </div>
                {/* <SelectCustom isMulti={true} onChange={setSelectedOption}
                    value={selectedOption}
                    options={options}
                    labelName={'Alias Name'}
                /> */}
                <Searchdropdown label={'Country Code'} src={'/assets/img/newarrow-down.png'} placeholder={'Select Country Code'} options={countryCodes} value={personalInfo?.countryCode} onChange={handleCountryCodes}/>
                <InputField type={"number"} errorMessage={isError["phoneNumber"]} labelName={'Phone Number'} placeholder={'Enter your Phone Number'} notImp={false} name={"phoneNumber"} onChange={onChange} value={personalInfo?.phoneNumber} />
                <InputField errorMessage={isError["email"]} labelName={'Email ID'} placeholder={'Enter your Email ID '} notImp={false} name={"email"} onChange={onChange} value={personalInfo?.email} />
                <InputField notImp={true} type={'url'} errorMessage={isError["linkedin"]} labelName={'LinkedIn'} placeholder={'Enter your LinkedIn link'} name={"linkedin"} onChange={onChange} value={personalInfo?.linkedin} />
                <InputField errorMessage={isError["twitter"]} labelName={'Twitter'} placeholder={'Enter your Twitter link'} notImp={true} name={"twitter"} onChange={onChange} value={personalInfo?.twitter} />
                <InputField errorMessage={isError["instagram"]} labelName={'Instagram'} placeholder={'Enter your Instagram link'} notImp={true} name={"instagram"} onChange={onChange} value={personalInfo?.instagram} />
                <InputField errorMessage={isError["facebook"]} labelName={'Facebook'} placeholder={'Enter your Facebook link'} notImp={true} name={"facebook"} onChange={onChange} value={personalInfo?.facebook} />
                <CustomDropdown errorMessage={isError["focusAreas"]} label={'Focus Areas'} className={'filterDrop'} dropName={'Enter Focus Areas'} options={DropdownValue} data={personalInfo?.focusAreas} isActive={dropdownToggler} setIsActive={setDropdownToggler} isimp={true} onChange={onChange} name={"focusAreas"} />
                <InputField errorMessage={isError["speciality"]} labelName={'Areas of Specialty'} placeholder={'Select your Additional Area of Specialty'} notImp={true} name={"speciality"} onChange={onChange} value={personalInfo?.speciality} />
                <InputField errorMessage={isError["doB"]} labelName={'Date Of Birth'} placeholder={'Select your Date'} notImp={true} type='date' name={"doB"} onChange={onChange} value={personalInfo?.doB} />
                <CustomDropdown label={'Enter Lead Source'} className={'filterDrop'} dropName={'Select'} options={DropdownLead} data={personalInfo?.leadSource} isActive={dropdownToggler3} setIsActive={setDropdownToggler3} onChange={onChange} name={"leadSource"} />

                {/* <SelectCustom isMulti={true} onChange={setSelectedOption}
                    value={selectedOption}
                    options={options}
                    labelName={'Residence City'}
                    placeholder={'Select your Residence City'}
                />
                <InputField notImp={true} type={'text'} labelName={'Residence Country'} placeholder={'Enter your Residence Country'} />
                <InputField notImp={true} type={'text'} labelName={'Residence Address'} placeholder={'Select your Residence Address'} />
                <InputField notImp={true} type={'text'} labelName={'Work City'} placeholder={'Enter your Work City'} />
                <InputField notImp={true} type={'text'} labelName={'Work Country'} placeholder={'Select your Work Country'} />
                <InputField notImp={true} type={'text'} labelName={'Work Address'} placeholder={'Enter your Work Address'} /> */}
                <Searchdropdown label={' Residence Country'} src={'/assets/img/newarrow-down.png'} placeholder={'Select Country'} options={countries} value={personalInfo?.country} onChange={handleCountryChange}/>
                {/* <CustomDropdown2 label={' Residence Country'} className={'filterDrop'} dropName={'Select your Country'} options={countries} data={selectedCountry ? selectedCountry : null} isActive={dropdownToggler9} setIsActive={setDropdownToggler9} onChange={handleCountryChange} name={"country"} preInfo={personalInfo?.country} /> */}
                <CustomDropdown2 label={' Residence State'} className={'filterDrop'} dropName={'Select your State'} options={states} data={selectedState ? selectedState : null} isActive={dropdownToggler4} setIsActive={setDropdownToggler4} onChange={handleStateChange} name={"residanceState"} preInfo={personalInfo?.residanceState} />
                <CustomDropdown2 label={' Residence City'} className={'filterDrop'} dropName={'Select your City'} options={cities} data={selectedCity ? selectedCity : null} isActive={dropdownToggler5} setIsActive={setDropdownToggler5} onChange={handleCityChange} name={"residanceCity"} preInfo={personalInfo?.residanceCity} />
                <InputField errorMessage={isError["address"]} labelName={'Residence Address'} placeholder={'Enter your Residence Address'} notImp={true} name={"address"} onChange={onChange} value={personalInfo?.address} />
                <Searchdropdown label={' Work Country'} src={'/assets/img/newarrow-down.png'} placeholder={'Select Country'} options={countries2} value={personalInfo?.workCountry} onChange={handleCountryChange2}/>
                {/* <CustomDropdown2 label={' Work Country'} className={'filterDrop'} dropName={'Select your Country'} options={countries2} data={selectedCountry2 ? selectedCountry2 : null} isActive={dropdownToggler6} setIsActive={setDropdownToggler6} onChange={handleCountryChange2} name={"workCountry"} preInfo={personalInfo?.workCountry} /> */}
                <CustomDropdown2 label={' Work State'} className={'filterDrop'} dropName={'Select your State'} options={states2} data={selectedState2 ? selectedState2 : null} isActive={dropdownToggler7} setIsActive={setDropdownToggler7} onChange={handleStateChange2} name={"workState"} preInfo={personalInfo?.workState} />
                <CustomDropdown2 label={' Work City'} className={'filterDrop'} dropName={'Select your City'} options={cities2} data={selectedCity2 ? selectedCity2 : null} isActive={dropdownToggler8} setIsActive={setDropdownToggler8} onChange={handleCityChange2} name={"workCity"} preInfo={personalInfo?.workCity} />
                <InputField errorMessage={isError["workAddress"]} labelName={'Work Address'} placeholder={'Enter your Work Address'} notImp={true} name={"workAddress"} onChange={onChange} value={personalInfo?.workAddress} preInfo={personalInfo?.country} />
            </div>
        </div>
    )
}

export default CreateMentorTab1