import React, { useEffect, useState } from 'react'
import TitleHeader from '../../../Layout/Header/TitleHeader'
import { CommentIcon, EyeIcon } from '../../../icon/icon'
import CreateMentorTab1 from './AllCreateMentorTab/CreateMentorTab1'
import CreateMentorTab2 from './AllCreateMentorTab/CreateMentorTab2'
import CreateMentorTab3 from './AllCreateMentorTab/CreateMentorTab3'
import CreateMentorTab4 from './AllCreateMentorTab/CreateMentorTab4'
import CreateMentorTab5 from './AllCreateMentorTab/CreateMentorTab5'
import Button from '../../../Components/Button/Button'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearState, addMasterDetail, updateMasterDetail, getAllMasters, getMasterDetails, masterSliceData } from "../../../reducer/masterSlice";
import SuccessToast from '../../../Components/Toast/SuccessToast'
import MasterExistPopup from '../../../Components/Popups/MasterExistPopup/MasterExistPopup'
import ErrorToast from '../../../Components/Toast/ErrorToast'
import PageLoader from '../../../Components/PageLoader/PageLoader'

const CreateAllMentor = ({ setShowAddDetails }) => {

  const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get('id'); // Get 'id' from URL
  console.log("id", id);

  const [isShowError, setIsShowError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [alreadyExist, setAlreadyExist] = useState(false)
  const [alreadyExistId, setAlreadyExistId] = useState("");

  const { allMasters, isFetching, isFetchingCsv, isMastersFetching, isError, errorMessage, isSuccess, emasterId, mentorDetails } = useSelector(masterSliceData);

  /////////////////////////States//////////////////////////////////////////////
  const [aliasState, setAliasState] = useState([]);
  const [personalInfo, setPersonalInfo] = useState({
    title: "",
    firstName: "",
    aliasName: [],
    lastName: "",
    leadSource: "",
    countryCode: "",
    phoneNumber: "",
    email: "",
    linkedin: "",
    twitter: "",
    instagram: "",
    facebook: "",
    focusAreas: "",
    speciality: "",
    leadSource: "",
    doB: "",
    residanceCity: "",
    residanceState: "",
    country: "",
    address: "",
    workCity: "",
    workState: "",
    workCountry: "",
    workAddress: "",
  });
  // console.log("personalInfo",personalInfo);

  const [bankDetails, setBankDetails] = useState(
    {
      // leads:"",
      bankName: "",
      accountName: "",
      accountNumber: "",
      accountType: "",
      ifscCode: "",
      routingNumber: "",
      gstNumber: "",
      panNumber: "",
      aadharNumber: "",
      // swiftCode:""
    }
  );

  const [photos, setPhotos] = useState({
    chequeUrl: {
      url: "",
      name: "",
    },
    panCardUrl: {
      url: "",
      name: "",
    },
    aadharCardUrl: {
      url: "",
      name: "",
    },
    otherPhotoUrl: {
      url: "",
      name: "",
    },
    profileUrl: {
      url: "",
      name: "",
    },
  });

  const [photos2, setPhotos2] = useState({
    chequeUrl: {
      url: "",
      name: "",
    },
    panCardUrl: {
      url: "",
      name: "",
    },
    aadharCardUrl: {
      url: "",
      name: "",
    },
    otherPhotoUrl: {
      url: "",
      name: "",
    },
  });

  const [educationDetails, setEducationDetails] = useState({
    undergradCollege: "",
    undergradDegree: "",
    undergradSpecialization: "",
    undergradYear: "",
    postgradCollege: "",
    postgradDegree: "",
    postgradSpecialization: "",
    postgradYear: "",
    phdCollege: "",
    phdArea: "",
    phdYear: "",
  });

  const [currentWork, setCurrentWork] = useState([
    {
      currentDesignation: "",
      currentCompany: "",
      currentLocation: "",
    },
  ]);

  const [previousWork, setPreviousWork] = useState([
    {
      previousDesignation: "",
      previousCompany: "",
      previousLocation: "",
    },
  ]);

  const [otherEducation, setOtherEducation] = useState([
    {
      otherCollege: "",
      degree: "",
      specialization: "",
      year: "",
    },
  ]);

  const [currentWorkCount, setCurrentWorkCount] = useState([0]);
  const [peviousWorkCount, setPrevioustWorkCount] = useState([0]);
  const [otherEducationCount, setOtherEducationCount] = useState([0]);

  const errorTemplate = {
    title: "",
    firstName: "",
    lastName: "",
    // cohort : "",
    countryCode: "",
    phoneNumber: "",
    email: "",
    linkedin: "",
    twitter: "",
    instagram: "",
    facebook: "",
    focusAreas: "",
    speciality: "",
    doB: "",
    residanceCity: "",
    residanceState: "",
    country: "",
    address: "",
    workCity: "",
    workState: "",
    workCountry: "",
    workAddress: "",
  };
  // const [currFomr, setCurrForm] = useState(1);
  const [errorMsg, setErrorMsg] = useState(errorTemplate)

  ////////////////////////////////////////////////////////////////////////////////
  const infoChange = (e) => {
    const { name, value } = e.target;
    setErrorMsg(errorTemplate)
    setPersonalInfo(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const infoBank = (e) => {
    const { name, value } = e.target;
    setErrorMsg(errorTemplate)
    setBankDetails(prev => ({ ...prev, [name]: value, }))

  }

  const infoEducation = (e) => {
    const { name, value } = e.target;
    setEducationDetails(prev => ({ ...prev, [name]: value, }))
  }

  const handleOtherEducation = (e, i) => {
    const { name, value } = e.target;
    // console.log(i,'index',name)
    let arr = [...otherEducation];
    let aj = { ...arr[i], [name]: value }
    arr[i] = aj
    // arr[i][name] = value;
    setOtherEducation([...arr]);
  }

  const handleCurrentWorkChange = (e, i) => {
    const { name, value } = e.target;
    // console.log(i,'index')
    let arr = [...currentWork];
    let aj = { ...arr[i], [name]: value }
    arr[i] = aj
    arr[i][name] = value;
    setCurrentWork([...arr]);
  }

  const handlePreviousWorkChange = (e, i) => {
    const { name, value } = e.target;
    // console.log(i,'index')
    let arr = [...previousWork];
    let aj = { ...arr[i], [name]: value }
    arr[i] = aj
    arr[i][name] = value;
    setPreviousWork([...arr]);
  }

  const object = {
    title: personalInfo.title,
    firstName: personalInfo.firstName,
    lastName: personalInfo.lastName,
    alias: aliasState.join(','),
    countryCode: personalInfo.countryCode,
    phoneNumber: personalInfo.phoneNumber,
    email: personalInfo.email,
    linkedin: personalInfo.linkedin,
    twitter: personalInfo.twitter,
    instagram: personalInfo.instagram,
    facebook: personalInfo.facebook,
    focusAreas: personalInfo.focusAreas,
    leadSource: personalInfo.leadSource,
    speciality: personalInfo.speciality,
    doB: personalInfo.doB,
    residanceCity: personalInfo.residanceCity,
    residanceState: personalInfo.residanceState,
    country: personalInfo.country,
    address: personalInfo.address,
    workCity: personalInfo.workCity,
    workState: personalInfo.workState,
    workCountry: personalInfo.workCountry,
    workAddress: personalInfo.workAddress,
    profileUrl: photos.profileUrl.url,
    undergradCollege: educationDetails.undergradCollege,
    undergradDegree: educationDetails.undergradDegree,
    undergradSpecialization: educationDetails.postgradSpecialization,
    undergradYear: educationDetails.undergradYear,
    postgradCollege: educationDetails.postgradCollege,
    postgradDegree: educationDetails.postgradDegree,
    postgradSpecialization: educationDetails.postgradSpecialization,
    postgradYear: educationDetails.postgradYear,
    phdCollege: educationDetails.phdCollege,
    phdArea: educationDetails.phdArea,
    phdYear: educationDetails.phdYear,
    // accountHolder: bankDetails.accountHolder,
    bankName: bankDetails.bankName,
    accountName: bankDetails.accountName,
    accountNumber: bankDetails.accountNumber,
    accountType: bankDetails.accountType,
    ifscCode: bankDetails.ifscCode,
    // swiftCode:bankDetails.swiftCode,
    routingNumber: bankDetails.routingNumber,
    gstNumber: bankDetails.gstNumber,
    panNumber: bankDetails.panNumber,
    aadharNumber: bankDetails.aadharNumber,
    chequeUrl: photos.chequeUrl.url,
    panCardUrl: photos.panCardUrl.url,
    aadharCardUrl: photos.aadharCardUrl.url,
    otherPhotoUrl: photos.otherPhotoUrl.url,
    currentWork,
    previousWork,
    otherEducation,
  };

  const Validate = () => {
    console.log("currentStep", currentStep);

    if (currentStep === 5) {
      const ignoredKeys = [
        "instagram", "title", "facebook", "twitter", "linkedin", "speciality",
        "workCity", "workCountry", "workAddress", "focusAreas", "doB", "residanceCity",
        "country", "address", "residanceState", "workState", "leadSource", "phoneNumber","countryCode"
      ];

      let hasError = false;
      let errors = {};

      for (const key in personalInfo) {
        if (ignoredKeys.includes(key)) continue;

        if (!personalInfo[key]) {
          errors[key] = "Please fill this field";
          hasError = true;
        }

        if (key === "email" && !/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(personalInfo["email"])) {
          errors[key] = "Please enter a valid Email";
          hasError = true;
        }
      }

      if (hasError) {
        setErrorMsg(errors);
        setCurrentStep(1);
        return false;
      }

      return true;
    }
  };

  const handleSubmit = () => {
    if (!Validate()) return; // Call Validate and proceed only if it returns true

    if (currentStep === 5 && id) {
      const obj = {
        masterId: id,
        obj: object
      };
      dispatch(updateMasterDetail(obj));
      console.log("subbmitupdate", obj);
    } else if (currentStep === 5) {
      dispatch(addMasterDetail(object));
      console.log("submitadd", object);
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getMasterDetails(id));
    }else{
      setPersonalInfo({
        title: "",
        firstName: "",
        aliasName: [],
        lastName: "",
        leadSource: "",
        countryCode: "",
        phoneNumber: "",
        email: "",
        linkedin: "",
        twitter: "",
        instagram: "",
        facebook: "",
        focusAreas: "",
        speciality: "",
        leadSource: "",
        doB: "",
        residanceCity: "",
        residanceState: "",
        country: "",
        address: "",
        workCity: "",
        workState: "",
        workCountry: "",
        workAddress: "",
      })
      setBankDetails({
        // leads:"",
        bankName: "",
        accountName: "",
        accountNumber: "",
        accountType: "",
        ifscCode: "",
        routingNumber: "",
        gstNumber: "",
        panNumber: "",
        aadharNumber: "",
        // swiftCode:""
      })
      setPhotos({
        chequeUrl: {
          url: "",
          name: "",
        },
        panCardUrl: {
          url: "",
          name: "",
        },
        aadharCardUrl: {
          url: "",
          name: "",
        },
        otherPhotoUrl: {
          url: "",
          name: "",
        },
        profileUrl: {
          url: "",
          name: "",
        },
      })
      setEducationDetails({
        undergradCollege: "",
        undergradDegree: "",
        undergradSpecialization: "",
        undergradYear: "",
        postgradCollege: "",
        postgradDegree: "",
        postgradSpecialization: "",
        postgradYear: "",
        phdCollege: "",
        phdArea: "",
        phdYear: "",
      })
      setCurrentWork([
        {
          currentDesignation: "",
          currentCompany: "",
          currentLocation: "",
        },
      ])
      setPreviousWork([
        {
          previousDesignation: "",
          previousCompany: "",
          previousLocation: "",
        },
      ])
      setOtherEducation([
        {
          otherCollege: "",
          degree: "",
          specialization: "",
          year: "",
        },
      ])
    }
  }, [])

  useEffect(() => {
    if(mentorDetails.length > 0 && id){
    setPersonalInfo((prevState) => ({
      ...prevState,
      title: mentorDetails[0]?.title,
      alias: (typeof mentorDetails[0]?.alias === 'string' ? [...mentorDetails[0]?.alias?.split(',')] : []),
      firstName: mentorDetails[0]?.firstName,
      lastName: mentorDetails[0]?.lastName,
      leadSource: mentorDetails[0]?.leadSource,
      countryCode: mentorDetails[0]?.countryCode,
      phoneNumber: mentorDetails[0]?.phoneNumber,
      email: mentorDetails[0]?.email,
      linkedin: mentorDetails[0]?.linkedin,
      twitter: mentorDetails[0]?.twitter,
      instagram: mentorDetails[0]?.instagram,
      facebook: mentorDetails[0]?.facebook,
      focusAreas: mentorDetails[0]?.focusAreas,
      speciality: mentorDetails[0]?.speciality,
      doB: mentorDetails[0]?.doB,
      residanceCity: mentorDetails[0]?.residanceCity,
      residanceState: mentorDetails[0]?.residanceState,
      country: mentorDetails[0]?.country,
      address: mentorDetails[0]?.address,
      workCity: mentorDetails[0]?.workCity,
      workState: mentorDetails[0]?.workState,
      workCountry: mentorDetails[0]?.workCountry,
      workAddress: mentorDetails[0]?.workAddress,
    }));
    setBankDetails((prevState) => ({
      ...prevState,
      // accountHolder:mentorDetails[0]?.BankDetailsCRMs[0]?.accountHolder,
      // leads:mentorDetails[0]?.BankDetailsCRMs[0]?.leads,
      bankName: mentorDetails[0]?.BankDetailsCRMs[0]?.bankName,
      accountName: mentorDetails[0]?.BankDetailsCRMs[0]?.accountName,
      accountNumber: mentorDetails[0]?.BankDetailsCRMs[0]?.accountNumber,
      accountType: mentorDetails[0]?.BankDetailsCRMs[0]?.accountType,
      ifscCode: mentorDetails[0]?.BankDetailsCRMs[0]?.ifscCode,
      routingNumber: mentorDetails[0]?.BankDetailsCRMs[0]?.routingNumber,
      gstNumber: mentorDetails[0]?.BankDetailsCRMs[0]?.gstNumber,
      panNumber: mentorDetails[0]?.BankDetailsCRMs[0]?.panNumber,
      aadharNumber: mentorDetails[0]?.BankDetailsCRMs[0]?.aadharNumber,
      // swiftCode: mentorDetails[0]?.BankDetailsCRMs[0]?.swiftCode,

    }))
    setEducationDetails((prevState) => ({
      ...prevState,
      undergradCollege: mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradCollege,
      undergradDegree: mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradDegree,
      undergradSpecialization: mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradSpecialization,
      undergradYear: mentorDetails[0]?.eductionDetailsCRMs[0]?.undergradYear,
      postgradCollege: mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradCollege,
      postgradDegree: mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradDegree,
      postgradSpecialization: mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradSpecialization,
      postgradYear: mentorDetails[0]?.eductionDetailsCRMs[0]?.postgradYear,
      phdCollege: mentorDetails[0]?.eductionDetailsCRMs[0]?.phdCollege,
      phdArea: mentorDetails[0]?.eductionDetailsCRMs[0]?.phdArea,
      phdYear: mentorDetails[0]?.eductionDetailsCRMs[0]?.phdYear,
    }));
    setPhotos(prev => ({
      ...prev,
      ["profileUrl"]: { url: mentorDetails[0]?.profileUrl, name: "profileUrl" },
      ["otherPhotoUrl"]: { url: mentorDetails[0]?.BankDetailsCRMs[0]?.otherPhotoUrl, name: "otherPhotoUrl" },
      ["aadharCardUrl"]: { url: mentorDetails[0]?.BankDetailsCRMs[0]?.aadharCardUrl, name: "aadharCardUrl" },
      ["panCardUrl"]: { url: mentorDetails[0]?.BankDetailsCRMs[0]?.panCardUrl, name: "panCardUrl" },
      ["chequeUrl"]: { url: mentorDetails[0]?.BankDetailsCRMs[0]?.chequeUrl, name: "chequeUrl" }

    }))
    setOtherEducation(mentorDetails[0]?.OtherEductionDetailsCRMs)
    if (mentorDetails[0]?.OtherEductionDetailsCRMs?.length > 0) {
      let arr = otherEducationCount;
      arr[mentorDetails[0]?.OtherEductionDetailsCRMs?.length - 1] = 0;
      setOtherEducationCount(arr);
    }

    setCurrentWork(mentorDetails[0]?.currentWorkCRMs)
    if (mentorDetails[0]?.currentWorkCRMs?.length > 0) {
      let arr = otherEducationCount;
      arr[mentorDetails[0]?.currentWorkCRMs?.length - 1] = 0;
      setCurrentWorkCount(arr);
    }
    setPreviousWork(mentorDetails[0]?.previousWorkCRMs)
    if (mentorDetails[0]?.currentWorkCRMs?.length > 0) {
      let arr = otherEducationCount;
      arr[mentorDetails[0]?.currentWorkCRMs?.length - 1] = 0;
      setPrevioustWorkCount(arr);
    }

  }
  }, [mentorDetails]);

  // error state 
  useEffect(() => {
    if (isError) {
      if (emasterId != "") {
        setShowAddDetails(false)
        dispatch(clearState());
        setAlreadyExistId(emasterId);
        setAlreadyExist(true);
      } else {
        setShowAddDetails(false)
        setIsShowError(true);
        setErrMessage(errorMessage);
        dispatch(clearState());
      }
    }

    if (isSuccess) {
      const obj = {
        focusAreas: "",
        currentLocation: "",
        engagement: "",
        search: "",
        pageNo: 1
      };
      dispatch(getAllMasters(obj));
      // dispatch(clearState());
      setShowAddDetails(false)
      setIsShowError(true);
      setErrMessage(errorMessage);
      setShowAddDetails(false)
      setAliasState([]);
      setPersonalInfo({
        title: "",
        firstName: "",
        aliasName: [],
        lastName: "",
        leadSource: "",
        countryCode: "",
        phoneNumber: "",
        email: "",
        linkedin: "",
        twitter: "",
        instagram: "",
        facebook: "",
        focusAreas: "",
        speciality: "",
        leadSource: "",
        doB: "",
        residanceCity: "",
        residanceState: "",
        country: "",
        address: "",
        workCity: "",
        workState: "",
        workCountry: "",
        workAddress: "",
      })
      setBankDetails({
        // leads:"",
        bankName: "",
        accountName: "",
        accountNumber: "",
        accountType: "",
        ifscCode: "",
        routingNumber: "",
        gstNumber: "",
        panNumber: "",
        aadharNumber: "",
        // swiftCode:""
      })
      setPhotos({
        chequeUrl: {
          url: "",
          name: "",
        },
        panCardUrl: {
          url: "",
          name: "",
        },
        aadharCardUrl: {
          url: "",
          name: "",
        },
        otherPhotoUrl: {
          url: "",
          name: "",
        },
        profileUrl: {
          url: "",
          name: "",
        },
      })
      setEducationDetails({
        undergradCollege: "",
        undergradDegree: "",
        undergradSpecialization: "",
        undergradYear: "",
        postgradCollege: "",
        postgradDegree: "",
        postgradSpecialization: "",
        postgradYear: "",
        phdCollege: "",
        phdArea: "",
        phdYear: "",
      })
      setCurrentWork([
        {
          currentDesignation: "",
          currentCompany: "",
          currentLocation: "",
        },
      ])
      setPreviousWork([
        {
          previousDesignation: "",
          previousCompany: "",
          previousLocation: "",
        },
      ])
      setOtherEducation([
        {
          otherCollege: "",
          degree: "",
          specialization: "",
          year: "",
        },
      ])
      setCurrentWorkCount([0])
      setPrevioustWorkCount([0])
      setOtherEducationCount([0])
      setCurrentStep(1)
      navigate(-1)
    }
  }, [isError, isSuccess]);

  return (
    <>
      {isFetching && <PageLoader />}
      <ErrorToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <SuccessToast
        show={isShowError}
        setShow={() => setIsShowError(false)}
        message={errMessage}
      />
      <MasterExistPopup
        alreadyExist={alreadyExist}
        setAlreadyExist={setAlreadyExist}
        emasterId={alreadyExistId}
      />
      <div className='createAllMentorPage'>
        <TitleHeader />

        <div className='blockDivideMentorCreate'>
          <div className='leftMentorProfile'>
            <div className='stepperBlock'>
              <div className={`blockIconStepper ${currentStep === 1 && 'active'} ${currentStep > 1 ? 'greenActive' : ""}`} onClick={() => setCurrentStep(1)}>
                <span className={`stepperBoxIcon`} >
                  <CommentIcon />
                </span>
                <p>Personal Info</p>
              </div>
              <div className={`blockIconStepper ${currentStep === 2 && 'active'}  ${currentStep > 2 ? 'greenActive' : ""} `} onClick={() => setCurrentStep(2)}>
                <span className={`stepperBoxIcon`} >
                  <CommentIcon />
                </span>
                <p>Bank Details</p>
              </div>
              <div className={`blockIconStepper ${currentStep === 3 && 'active'} ${currentStep > 3 ? 'greenActive' : ""} `} onClick={() => setCurrentStep(3)}>
                <span className={`stepperBoxIcon`} >
                  <CommentIcon />
                </span>
                <p>Education</p>
              </div>
              <div className={`blockIconStepper ${currentStep === 4 && 'active'} ${currentStep > 4 ? 'greenActive' : ""} `} onClick={() => setCurrentStep(4)}>
                <span className={`stepperBoxIcon`} >
                  <CommentIcon />
                </span>
                <p>Work Experience</p>
              </div>
              <div className={`blockIconStepper ${currentStep === 5 && 'active'}`} onClick={() => setCurrentStep(5)}>
                <span className={`stepperBoxIcon`} >
                  <EyeIcon color={'#5243AA'} />
                </span>
                <p>Preview</p>
              </div>
            </div>
          </div>

          {currentStep !== 5 && <div className='rightMentorProfile'>
            {currentStep === 1 && <CreateMentorTab1 currentStep={currentStep} setCurrentStep={setCurrentStep} personalInfo={personalInfo} onChange={infoChange} setPhotos={setPhotos} photos={photos} isError={errorMsg} aliasState={aliasState} setAliasState={setAliasState} setPersonalInfo={setPersonalInfo} />}
            {currentStep === 2 && <CreateMentorTab2 currentStep={currentStep} setCurrentStep={setCurrentStep} bankDetails={bankDetails} onChange={infoBank} setPhotos={setPhotos} photos={photos} setPhotos2={setPhotos2} photos2={photos2} infoBank={infoBank} />}
            {currentStep === 3 && <CreateMentorTab3 currentStep={currentStep} setCurrentStep={setCurrentStep} educationDetails={educationDetails} onChange={infoEducation} otherEducationCount={otherEducationCount} setOtherEducationCount={setOtherEducationCount} handleOtherEducation={handleOtherEducation} setOtherEducation={setOtherEducation} otherEducation={otherEducation} />}
            {currentStep === 4 && <CreateMentorTab4 currentStep={currentStep} setCurrentStep={setCurrentStep} currentWorkCount={currentWorkCount} setCurrentWorkCount={setCurrentWorkCount} handleCurrentWorkChange={handleCurrentWorkChange} setCurrentWork={setCurrentWork} handlePreviousWorkChange={handlePreviousWorkChange} peviousWorkCount={peviousWorkCount} setPrevioustWorkCount={setPrevioustWorkCount} setPreviousWork={setPreviousWork} currentWork={currentWork} previousWork={previousWork} />}
            <div className='borderHeaderLineBlock flexend'>
              {currentStep == 1 ? "" : <Button text={'Back'} className={'GreyFillButton'} onClick={() => setCurrentStep((prev => prev === 1 ? prev : --prev))} />}
              <Button text={`${currentStep === 5 ? 'Submit' : 'Next'}`} className={'BlueFillButton'} onClick={() => setCurrentStep(currentStep + 1)} />
            </div>
          </div>}
          {currentStep === 5 && <CreateMentorTab5 currentStep={currentStep} setCurrentStep={setCurrentStep} Data={object} handleSubmit={handleSubmit} />}

        </div>
      </div>
    </>
  )
}

export default CreateAllMentor