import { useState } from "react";
import { Route, Routes, useLocation } from "react-router";
import "./App.css";
import FilterSlide from "./Components/FilterSlide/FilterSlide";
import CashDetailPopup from "./Components/Popups/CashDetailPopup/CashDetailPopup";
import MastersDeatailPopup from "./Components/Popups/MastersDeatailPopup/MastersDeatailPopup";
import Header from "./Layout/Header/Header";
import Home from "./Pages/Home/Home";
import MasterDetail from "./Pages/MasterDetail/MasterDetail";
import LoginPage from "./Pages/authentication/LoginPage";
import { PrivateRoute } from "./helpers/PrivateRoute";
import CSVPopup from "./Components/Popups/CSVPopup/CSVPopup";
import AllMastersList from "./Pages/AllMasters/AllMastersList";
import CreateAllMentor from "./Pages/AllMasters/CreateAllMentor/CreateAllMentor";
import AdvanceFilter from "./Components/AdvanceFilter/AdvanceFilter";

function App() {
  const location = useLocation();
  const [showFilter, setshowFilter] = useState(false);
  const [showAddDetails, setShowAddDetails] = useState(false);
  const [showAddDetails2, setShowAddDetails2] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [searchTerm, setSearchTearm] = useState("");
  const [masterId, setMasterId] = useState(null);
  const [message, setMessage] = useState('');
  // const [filter, setFilter] = useState({
  //   focusAreas: "",
  //   currentLocation: "",
  //   engagement: "",
  // })


  const [totalMentors, setTotalMentors] = useState([]);
  const initialFilterState = {
    engagement:'',
    currentLocation: "",
    engagementType: '',
    programType: '',
    focusAreas: '',
    accHandler: '',
    engagementStatus: '',
    tiering: '',
    cohort: '',
    courseType : '',
    leadStage: '',
    leadSource: '',
    deliveryMode: '',
    industryType: '',
    dateRange: null,

  };

  const [filters, setFilters] = useState(initialFilterState);
  
// console.log("masterId", masterId);

  return (
    <>
      <CSVPopup
        setShowAddDetails2={setShowAddDetails2}
        showAddDetails2={showAddDetails2}

      />
      <div className="mainContainer">
        {/* <FilterSlide showFilter={showFilter} setshowFilter={setshowFilter}
          filter={filter} setFilter={setFilter}
          setTotalMentors={setTotalMentors} totalMentors={totalMentors}
        /> */}
        <AdvanceFilter showFilter={showFilter} setshowFilter={setshowFilter}
          filters={filters} setFilters={setFilters}
          setTotalMentors={setTotalMentors} totalMentors={totalMentors}
        />
        <Header
          setShowAddDetails={setShowAddDetails}
          setShowAddDetails2={setShowAddDetails2}
          message={message}
          setMessage={setMessage}
          setSearchTearm={setSearchTearm}
          filters={filters}
          setShowLogout={setShowLogout}
          setTotalMentors={setTotalMentors}
          masterId={masterId}

        />
        <div
          className={`mainWrapper 
            ${location.pathname === '/create-all-mentor' && 'paddingTop0'}
            ${location.pathname === "/" && "singleHeader" ||
            location.pathname === "/login" ||
            location.pathname === "/all-mentor" && 'fullHeight'}`}
        >
          <Routes>
            <Route path="/login" exact element={<LoginPage />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route
                exact
                path="/"
                element={
                  // <Home
                  //   setshowFilter={setshowFilter}
                  //   showAddDetails={showAddDetails}
                  //   setShowAddDetails={setShowAddDetails}
                  //   filter={filter}
                  //   searchTerm={searchTerm}
                  //   message={message}
                  //   setShowLogout={setShowLogout}
                  //   showLogout={showLogout}
                  //   setTotalMentors={setTotalMentors} totalMentors={totalMentors}

                  // />
                  <AllMastersList
                setshowFilter={setshowFilter}
                setShowAddDetails={setShowAddDetails}
                setShowAddDetails2={setShowAddDetails2}
                message={message}
                setMessage={setMessage}
                searchTerm={searchTerm}
                setSearchTearm={setSearchTearm}
                filter={filters}
                setShowLogout={setShowLogout}
                showLogout={showLogout}
                setTotalMentors={setTotalMentors}
                totalMentors={totalMentors}
                filters={filters}
                setFilters={setFilters}
                initialFilterState={initialFilterState}
              />
                }
              />
              <Route
                exact
                path="/aboutMaster/:id"
                element={
                  <MasterDetail
                    showAddDetails={showAddDetails}
                    setShowAddDetails={setShowAddDetails}
                    setMasterId={setMasterId}
                  />
                }
              />

            </Route>

            {/* <Route
              exact
              path="/all-mentor"
              element={<AllMastersList
                setshowFilter={setshowFilter}
                setShowAddDetails={setShowAddDetails}
                setShowAddDetails2={setShowAddDetails2}
                message={message}
                setMessage={setMessage}
                setSearchTearm={setSearchTearm}
                filter={filter}
                setShowLogout={setShowLogout}
                showLogout={showLogout}
                setTotalMentors={setTotalMentors}
                totalMentors={totalMentors}
                filters={filters}
                setFilters={setFilters}
                initialFilterState={initialFilterState}
              />}
            /> */}
            <Route
              exact
              path="/create-all-mentor"
              element={<CreateAllMentor  setShowAddDetails={setShowAddDetails}/>}
            />


          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
