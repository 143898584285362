import React from 'react';
import style from './SingleSelectDropdown.module.css';
import { MultiSelect } from 'react-multi-select-component';
import { CrossIcon } from '../../icon/icon';
import Select from 'react-select';

const SingleSelectDropdown = ({
    className,
    options,
    label,
    placeholder,
    isSearchable = true,
    selected = null,
    onChange,
    errorMessage,
    onClear,
    menuIsOpen,
    customStyles,
}) => {


    const defaultStyle = {
        control: (base) => ({
            ...base,
            border: "none",
            borderRadius: "10px",
            padding: "0",
            backgroundColor: "transparent",
            boxShadow: "none",
            minHeight: "30px",
            width: customStyles ? "100%" : "200px",
            "&:hover": {
                border: "none",
            },
        }),
        option: (base, { isFocused, isSelected }) => ({
            ...base,
            backgroundColor: isSelected ? "#007bff" : isFocused ? "#cce5ff" : "#fff",
            color: isSelected ? "#fff" : "#333",
            cursor: "pointer",
            padding: "8px 12px",
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: "#FFF",
            color: "#404040",
            borderRadius: "6px",
            padding: "4px 8px",
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            border: "1px solid #E5E5E5"
        }),
        multiValueLabel: (base) => ({
            ...base,
            color: "#404040",
            fontSize: "12px",
            padding: "0 6px",
        }),
        multiValueRemove: (base) => ({
            ...base,
            color: "#fff",
            cursor: "pointer",
            marginLeft: "4px",
            borderRadius: "50%",
            width: "16px",
            height: "16px",
            padding: "4px",
            transition: "background-color 0.2s",
            ":hover": {
                backgroundColor: "#101010",
                color: "#fff",
            },
        }),
        placeholder: (base) => ({
            ...base,
            color: "#999",
            fontSize: "14px",
        }),
        menu: (base) => ({
            ...base,
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }),
    };

    return (
        <div className={`${style.parentMultiSelect}`}>
            <div className={`${style.multiSelectBlock} multiSelectBlockCustom ${className}`}>
                {selected && (
                    <div onClick={onClear} className={style.tagFilterWrap} style={{ cursor: "pointer" }}>
                        <CrossIcon />
                        <p className='text14n mb8'>{label}</p>
                    </div>
                )}
                <Select
                    options={options}
                    value={selected}
                    onChange={onChange}
                    placeholder={placeholder}
                    isSearchable={isSearchable}
                    styles={defaultStyle}
                    classNamePrefix="customSelect"
                    menuIsOpen={menuIsOpen}


                />
            </div>
            {errorMessage && <span className={`textRed ${style.errorTextMessage}`}>{errorMessage}</span>}
        </div>
    );
};

export default SingleSelectDropdown;
