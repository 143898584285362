import './SearchdropdownStyle.css'
import { useState } from 'react'
import { useEffect, useRef } from 'react';
import React from 'react';

const Searchdropdown = ({ placeholder, src, options, value, onChange, className, name, disabled, countryCode,label }) => {

    const [filteredOptions, setFilteredOptions] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [search, setSearch] = useState("");

    const ref = useRef();

    useEffect(() => {
        setFilteredOptions(options?.filter(item => item?.label?.toLowerCase()?.includes(search?.toLowerCase())));
    }, [search, options]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsActive(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    // console.log(value);
    

    return (
        <div className={`CustomDropdownmain ${className}  ${isActive && "active"} ${countryCode && 'countryCode'}`} ref={ref} >
            <div>
            <label className='labelName'>{label} </label>
                <div className="dropdownselect flex-between" onClick={() => setIsActive(!isActive)}>
                    <span className={`text3 `}>{value?.length > 0 ? filteredOptions?.filter(item => item?.label === value)[0]?.label : placeholder}</span>
                    <img src={src} alt="downarrow_icon" className='downarrow' />
                </div>
                <div className="ListthatSelected">
                    <label htmlFor="search" className="inputsearchdsAs">
                        <input type="search" disabled={disabled} name="" id="search" placeholder='Search' value={search} onChange={e => setSearch(e.target.value)} />
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <mask id="path-1-inside-1_1708_7576" fill="white">
                            <path d="M6.70841 12.6878C3.41258 12.6878 0.729248 10.0045 0.729248 6.70866C0.729248 3.41283 3.41258 0.729492 6.70841 0.729492C10.0042 0.729492 12.6876 3.41283 12.6876 6.70866C12.6876 10.0045 10.0042 12.6878 6.70841 12.6878ZM6.70841 1.60449C3.89091 1.60449 1.60425 3.89699 1.60425 6.70866C1.60425 9.52033 3.89091 11.8128 6.70841 11.8128C9.52591 11.8128 11.8126 9.52033 11.8126 6.70866C11.8126 3.89699 9.52591 1.60449 6.70841 1.60449Z" />
                        </mask>
                        <path d="M6.70841 12.6878C3.41258 12.6878 0.729248 10.0045 0.729248 6.70866C0.729248 3.41283 3.41258 0.729492 6.70841 0.729492C10.0042 0.729492 12.6876 3.41283 12.6876 6.70866C12.6876 10.0045 10.0042 12.6878 6.70841 12.6878ZM6.70841 1.60449C3.89091 1.60449 1.60425 3.89699 1.60425 6.70866C1.60425 9.52033 3.89091 11.8128 6.70841 11.8128C9.52591 11.8128 11.8126 9.52033 11.8126 6.70866C11.8126 3.89699 9.52591 1.60449 6.70841 1.60449Z" fill="#737373" />
                        <path d="M6.70841 11.6878C3.96487 11.6878 1.72925 9.45221 1.72925 6.70866H-0.270752C-0.270752 10.5568 2.8603 13.6878 6.70841 13.6878V11.6878ZM1.72925 6.70866C1.72925 3.96511 3.96487 1.72949 6.70841 1.72949V-0.270508C2.8603 -0.270508 -0.270752 2.86054 -0.270752 6.70866H1.72925ZM6.70841 1.72949C9.45196 1.72949 11.6876 3.96511 11.6876 6.70866H13.6876C13.6876 2.86054 10.5565 -0.270508 6.70841 -0.270508V1.72949ZM11.6876 6.70866C11.6876 9.45221 9.45196 11.6878 6.70841 11.6878V13.6878C10.5565 13.6878 13.6876 10.5568 13.6876 6.70866H11.6876ZM6.70841 0.604492C3.33789 0.604492 0.604248 3.34545 0.604248 6.70866H2.60425C2.60425 4.44853 4.44394 2.60449 6.70841 2.60449V0.604492ZM0.604248 6.70866C0.604248 10.0719 3.33789 12.8128 6.70841 12.8128V10.8128C4.44394 10.8128 2.60425 8.96878 2.60425 6.70866H0.604248ZM6.70841 12.8128C10.0789 12.8128 12.8126 10.0719 12.8126 6.70866H10.8126C10.8126 8.96878 8.97289 10.8128 6.70841 10.8128V12.8128ZM12.8126 6.70866C12.8126 3.34545 10.0789 0.604492 6.70841 0.604492V2.60449C8.97289 2.60449 10.8126 4.44853 10.8126 6.70866H12.8126Z" fill="#737373" mask="url(#path-1-inside-1_1708_7576)" />
                        <mask id="path-3-inside-2_1708_7576" fill="white">
                            <path d="M12.8334 13.2707C12.7226 13.2707 12.6118 13.2298 12.5243 13.1423L11.3576 11.9757C11.1884 11.8065 11.1884 11.5265 11.3576 11.3573C11.5268 11.1882 11.8068 11.1882 11.9759 11.3573L13.1426 12.524C13.3118 12.6932 13.3118 12.9732 13.1426 13.1423C13.0551 13.2298 12.9443 13.2707 12.8334 13.2707Z" />
                        </mask>
                        <path d="M12.5243 13.1423L11.8171 13.8495L12.5243 13.1423ZM11.3576 11.9757L12.0647 11.2686V11.2686L11.3576 11.9757ZM11.9759 11.3573L11.2688 12.0645L11.9759 11.3573ZM13.1426 12.524L13.8497 11.8169L13.1426 12.524ZM12.8334 12.2707C12.9735 12.2707 13.1214 12.3253 13.2314 12.4352L11.8171 13.8495C12.1021 14.1344 12.4717 14.2707 12.8334 14.2707V12.2707ZM13.2314 12.4352L12.0647 11.2686L10.6505 12.6828L11.8171 13.8495L13.2314 12.4352ZM12.0647 11.2686C12.2861 11.4899 12.2861 11.8431 12.0647 12.0645L10.6505 10.6502C10.0908 11.2099 10.0908 12.1231 10.6505 12.6828L12.0647 11.2686ZM12.0647 12.0645C11.8433 12.2858 11.4902 12.2858 11.2688 12.0645L12.683 10.6502C12.1233 10.0905 11.2102 10.0905 10.6505 10.6502L12.0647 12.0645ZM11.2688 12.0645L12.4355 13.2311L13.8497 11.8169L12.683 10.6502L11.2688 12.0645ZM12.4355 13.2311C12.2141 13.0098 12.2141 12.6566 12.4355 12.4352L13.8497 13.8495C14.4094 13.2898 14.4094 12.3766 13.8497 11.8169L12.4355 13.2311ZM12.4355 12.4352C12.5454 12.3253 12.6934 12.2707 12.8334 12.2707V14.2707C13.1952 14.2707 13.5648 14.1344 13.8497 13.8495L12.4355 12.4352Z" fill="#737373" mask="url(#path-3-inside-2_1708_7576)" />
                    </svg> */}
                    </label>

                    <ul className='listwrapfordropdown'>
                        {
                            !disabled && filteredOptions?.map((item, index) =>
                                <li className='text3' key={index} onClick={() => { onChange(item); setIsActive(false); setSearch(""); }} >
                                    <p>{item?.label}</p>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Searchdropdown

