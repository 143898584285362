import React from 'react'
import Button from '../../../../Components/Button/Button'
import { DownloadFile, EyeIcon } from '../../../../icon/icon'

const CreateMentorTab5 = ({ currentStep, setCurrentStep, Data, handleSubmit }) => {
    // console.log(Data)
    return (
        <div className='blockDetailVertical'>
            <div className='rightMentorProfile'>
                <div className='createMentorTab5'>
                    <div className='headingBorderBlock'>
                        <h3 className='text12Upper'>Personal Info</h3>
                    </div>

                    <div className='profilePhotoBlock'>
                        <span className='text14m'>Profile Picture</span>
                        <img src={Data?.profileUrl ? Data?.profileUrl : "/assets/svg/profileIcon.svg"} alt="" className='profileImage' />
                    </div>

                    <div className='columnThreeGap18'>
                        <div className='detailBlockData'>
                            <h3>First Name</h3>
                            <p>{Data?.firstName != "" ? Data?.firstName : "---"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Last Name</h3>
                            <p>{Data?.lastName != "" ? Data?.lastName : "---"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Alias Name</h3>
                            <p>{Data?.alias != "" ? Data?.alias : "---"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Country Code</h3>
                            <p>{Data?.countryCode != "" ? Data?.countryCode : "---"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Phone Number</h3>
                            <p>{Data?.phoneNumber != "" ? Data?.phoneNumber : "---"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Email ID</h3>
                            <p>{Data?.email != "" ? Data?.email : "---"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>LinkedIn</h3>
                            <p className='linkUnderline'><p>{Data?.linkedin != "" ? Data?.linkedin : "N/A"}</p></p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Twitter</h3>
                            <p className='linkUnderline'>{Data?.twitter != "" ? Data?.twitter : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Instagram</h3>
                            <p className='linkUnderline'>{Data?.instagram != "" ? Data?.instagram : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Facebook</h3>
                            <p className='linkUnderline'>{Data?.facebook != "" ? Data?.facebook : "N/A"}</p>
                        </div>
                        {/* <div className='detailBlockData'>
                            <h3>Facebook</h3>
                            <div className='tagBLockWrapInfo'>
                                <div className='tagBlockInfo'>
                                    Teaching
                                </div>
                                <div className='tagBlockInfo'>
                                    Teaching
                                </div>
                                <div className='tagBlockInfo'>
                                    +3
                                </div>

                            </div>
                        </div> */}

                        <div className='detailBlockData'>
                            <h3>Focus Areas</h3>
                            <p>{Data?.focusAreas != "" ? Data?.focusAreas : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Additional Area of Specialty</h3>
                            <p>{Data?.speciality != "" ? Data?.speciality : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Date Of Birth</h3>
                            <p>{Data?.doB != "" ? Data?.doB : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Residence Country</h3>
                            <p>{Data?.country != "" ? Data?.country : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Residence State</h3>
                            <p>{Data?.residanceState != "" ? Data?.residanceState : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Residence City</h3>
                            <p><p>{Data?.state != "" ? Data?.state : "N/A"}</p></p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Residence Address</h3>
                            <p><p>{Data?.address != "" ? Data?.address : "N/A"}</p></p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Work Country</h3>
                            <p>{Data?.workCountry != "" ? Data?.workCountry : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Work State</h3>
                            <p>{Data?.workState != "" ? Data?.workState : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Work City</h3>
                            <p><p>{Data?.workCity != "" ? Data?.workCity : "N/A"}</p></p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Work Address</h3>
                            <p><p>{Data?.workAddress != "" ? Data?.workAddress : "N/A"}</p></p>
                        </div>


                    </div>
                </div>
            </div>

            <div className='rightMentorProfile'>
                <div className='createMentorTab5'>
                    <div className='headingBorderBlock'>
                        <h3 className='text12Upper'>BANK DETAILS</h3>
                    </div>

                    <div className='columnTwoGap18 mt-20'>
                        <div className='detailBlockData'>
                            <h3>Bank Name</h3>
                            <p>{Data?.bankName != "" ? Data?.bankName : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Account Name</h3>
                            <p>{Data?.accountName != "" ? Data?.accountName : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Account Number</h3>
                            <p>{Data?.accountNumber != "" ? Data?.accountNumber : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Account Type</h3>
                            <p>{Data?.accountType != "" ? Data?.accountType : "N/A"}</p>
                        </div>


                        <div className='detailBlockData'>
                            <h3>IFSC / Swift Code</h3>
                            <p>{Data?.ifscCode != "" ? Data?.ifscCode : "N/A"}</p>
                        </div>

                        <div className='detailBlockData'>
                            <h3>Routing Number</h3>
                            <p>{Data?.routingNumber != "" ? Data?.routingNumber : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>GST Number</h3>
                            <p>{Data?.gstNumber != "" ? Data?.gstNumber : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>PAN Number</h3>
                            <p>{Data?.panNumber != "" ? Data?.panNumber : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Aadhar Number</h3>
                            <p>{Data?.aadharNumber != "" ? Data?.aadharNumber : "N/A"}</p>
                        </div>


                        <div className='detailBlockData'>
                            <h3>Cancelled Cheque</h3>
                            <div className='sampleBlockFile'>
                                <div className='fileDs'>
                                    <img src={"/assets/svg/fileIcon.svg"} alt="" />
                                    <div className='fileDsContent'>
                                        <h4>{Data?.chequeUrl != '' ? "Uploded Img" : "N/A"}</h4>
                                        {/* <span>15 MB ZIP</span> */}
                                    </div>
                                </div>
                                <div className='iconWrapFileBox'>
                                    <span className='borderRightFile' onClick={() => Data?.chequeUrl != ''?  window.open(Data?.chequeUrl):""} >
                                        <EyeIcon />
                                    </span>

                                    {/* <span >
                                        <DownloadFile />
                                    </span> */}
                                </div>
                            </div>
                        </div>

                        <div className='detailBlockData'>
                            <h3>PAN Card Photo</h3>
                            <div className='sampleBlockFile'>
                                <div className='fileDs'>
                                    <img src="/assets/svg/fileIcon.svg" alt="" />
                                    <div className='fileDsContent'>
                                        <h4>{Data?.panCardUrl != '' ? "Uploded Img" : "N/A"}</h4>
                                        {/* <span>15 MB ZIP</span> */}
                                    </div>
                                </div>
                                <div className='iconWrapFileBox'>
                                    <span className='borderRightFile' onClick={() => Data?.panCardUrl != '' ? window.open(Data?.panCardUrl) :""} >
                                        <EyeIcon />
                                    </span>

                                    {/* <span>
                                        <DownloadFile />
                                    </span> */}
                                </div>
                            </div>
                        </div>

                        <div className='detailBlockData'>
                            <h3>Aadhar Card Photo</h3>
                            <div className='sampleBlockFile'>
                                <div className='fileDs'>
                                    <img src="/assets/svg/fileIcon.svg" alt="" />
                                    <div className='fileDsContent'>
                                        <h4>{Data?.aadharCardUrl != '' ? "Uploded Img" : "N/A"}</h4>
                                        {/* <span>15 MB ZIP</span> */}
                                    </div>
                                </div>
                                <div className='iconWrapFileBox'>
                                    <span className='borderRightFile' onClick={() => Data?.aadharCardUrl != '' ? window.open(Data?.aadharCardUrl):""} >
                                        <EyeIcon />
                                    </span>

                                    {/* <span>
                                        <DownloadFile />
                                    </span> */}
                                </div>
                            </div>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Other Photo</h3>
                            <div className='sampleBlockFile'>
                                <div className='fileDs'>
                                    <img src="/assets/svg/fileIcon.svg" alt="" />
                                    <div className='fileDsContent'>
                                        <h4>{Data?.otherPhotoUrl != '' ? "Uploded Img" : "N/A"}</h4>
                                        {/* <span>15 MB ZIP</span> */}
                                    </div>
                                </div>
                                <div className='iconWrapFileBox'>
                                    <span className='borderRightFile' onClick={() => Data?.otherPhotoUrl != ''? window.open(Data?.otherPhotoUrl) : ""} >
                                        <EyeIcon />
                                    </span>

                                    {/* <span>
                                        <DownloadFile />
                                    </span> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='rightMentorProfile'>
                <div className='createMentorTab5'>
                    <div className='headingBorderBlock'>
                        <h3 className='text12Upper'>EDUCATION</h3>
                    </div>

                    <div className='columnTwoGap18 mt-20'>
                        <div className='detailBlockData'>
                            <h3>Undergrad College</h3>
                            <p>{Data?.undergradCollege != "" ? Data?.undergradCollege : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Undergrad Degree</h3>
                            <p>{Data?.undergradDegree != "" ? Data?.undergradDegree : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Undergrad Specialization</h3>
                            <p>{Data?.undergradSpecialization != "" ? Data?.undergradSpecialization : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Undergrad Year</h3>
                            <p>{Data?.undergradYear != "" ? Data?.undergradYear : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Postgrad College</h3>
                            <p>{Data?.postgradCollege != "" ? Data?.postgradCollege : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Postgrad Degree</h3>
                            <p>{Data?.postgradDegree != "" ? Data?.postgradDegree : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Postgrad Specialization</h3>
                            <p>{Data?.postgradSpecialization != "" ? Data?.postgradSpecialization : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>Postgrad Year</h3>
                            <p>{Data?.postgradYear != "" ? Data?.postgradYear : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>PhD College</h3>
                            <p>{Data?.phdCollege != "" ? Data?.phdCollege : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>PhD Area</h3>
                            <p>{Data?.phdArea != "" ? Data?.phdArea : "N/A"}</p>
                        </div>
                        <div className='detailBlockData'>
                            <h3>PhD Year</h3>
                            <p>{Data?.phdYear != "" ? Data?.phdYear : "N/A"}</p>
                        </div>
                    </div>

                    <div className='borderLine'></div>

                    {Data?.otherEducation.length > 0 &&
                        Data?.otherEducation.map((item, index) => (
                            <div className='columnTwoGap18'>
                                <div className='detailBlockData'>
                                    <h3>Other College {index + 1}</h3>
                                    <p>{item?.otherCollege != "" ? item?.otherCollege : "N/A"}</p>
                                </div>
                                <div className='detailBlockData'>
                                    <h3>Degree</h3>
                                    <p>{item?.degree != "" ? item?.degree : "N/A"}</p>
                                </div>
                                <div className='detailBlockData'>
                                    <h3>Specialization</h3>
                                    <p>{item?.specialization != "" ? item?.specialization : "N/A"}</p>
                                </div>
                                <div className='detailBlockData'>
                                    <h3>Year</h3>
                                    <p>{item?.year != "" ? item?.year : "N/A"}</p>
                                </div>
                            </div>
                        ))}
                </div>
            </div>

            <div className='rightMentorProfile'>
                <div className='createMentorTab5'>
                    <div className='headingBorderBlock'>
                        <h3 className='text12Upper'>WORK EXPERIENCE</h3>
                    </div>

                    {Data?.currentWork.length > 0 &&
                        Data?.currentWork.map((item, index) => (
                            <div className='columnTwoGap18 mt-20'>
                                <div className='detailBlockData'>
                                    <h3>Current Designation - {index + 1}</h3>
                                    <p>{item?.currentDesignation != "" ? item?.currentDesignation : "N/A"}</p>
                                </div>
                                <div className='detailBlockData'>
                                    <h3>Current Company - {index + 1}</h3>
                                    <p>{item?.currentCompany != "" ? item?.currentCompany : "N/A"}</p>
                                </div>
                                <div className='detailBlockData'>
                                    <h3>Current Location - {index + 1}</h3>
                                    <p>{item?.currentLocation != "" ? item?.currentLocation : "N/A"}</p>
                                </div>
                            </div>
                        ))}

                    <div className='borderLine'></div>

                    {Data?.previousWork.length > 0 &&
                        Data?.previousWork.map((item, index) => (
                            <div className='columnTwoGap18'>
                                <div className='detailBlockData'>
                                    <h3>Previous Designation - {index + 1}</h3>
                                    <p>{item?.previousDesignation != "" ? item?.previousDesignation : "N/A"}</p>
                                </div>
                                <div className='detailBlockData'>
                                    <h3>Previous Company - {index + 1}</h3>
                                    <p>{item?.previousCompany != "" ? item?.previousCompany : "N/A"}</p>
                                </div>
                                <div className='detailBlockData'>
                                    <h3>Previous Location - {index + 1}</h3>
                                    <p>{item?.previousLocation != "" ? item?.previousLocation : "N/A"}</p>
                                </div>
                            </div>
                        ))}
                </div>

                <div className='borderHeaderLineBlock flexend'>
                    <Button text={'Back'} className={'GreyFillButton'} onClick={() => setCurrentStep((prev => prev === 1 ? prev : --prev))} />
                    <Button text={'Save & Add Master'} className={'BlueFillButton'} onClick={()=> handleSubmit()}/>
                </div>
            </div>
        </div >
    )
}

export default CreateMentorTab5